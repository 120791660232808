import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';

import { AppSettings } from './../../app.settings';

@Injectable()
export class AuthApiService {
    constructor(private _httpClient: HttpClient) {}

    obterInformacoesDoLogin(token: any): Observable<any> {
        // console.log("######## API RECUPERAR LOGIN ########")

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);

        // const url = `${AppSettings.API_ENDPOINT_AUTH}/auth/otkweb-information`;
        const url = `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/otkweb-information`;

        return this._httpClient.get(url, { headers });
    }

    autenticar(email: string, senha: string): Observable<any> {
        // console.log("######## API AUTENTICAR ########")

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            `application/x-www-form-urlencoded`
        );

        let params = new HttpParams();
        params = params.set('grant_type', 'password');
        params = params.set('username', email);
        params = params.set('password', senha);

        // const url = `${AppSettings.API_ENDPOINT_AUTH}/auth/client`;
        const url = `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/cliente`;

        return this._httpClient
            .post<any>(url, params.toString(), { headers })
            .pipe(
                tap(() => AppSettings.limparToken()),
                map((r: any) => {
                    AppSettings.armazenarToken(r.access_token);
                    return r.access_token;
                })
            );
    }

    autenticarLoginInterno(
        email: string,
        senha: string,
        codigoDeEmpresa: number,
        codigoDoUsuario: string
    ): Observable<any> {
        // console.log("######## API AUTENTICAR LOGIN INTERNO ########")

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            `application/x-www-form-urlencoded`
        );

        let params = new HttpParams();
        params = params.set('grant_type', 'client_credentials');
        params = params.set('client_id', email);
        params = params.set('client_secret', senha);
        params = params.set('user', codigoDoUsuario);
        params = params.set('code', codigoDeEmpresa.toString());

        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/login-interno-cliente`,
                params.toString(),
                { headers }
            )
            .pipe(
                map((r: any) => {
                    AppSettings.armazenarToken(r.access_token);
                    return r.access_token;
                })
            );
    }

    autenticarLoginInternoAdmin(
        email: string,
        senha: string,
        codigoDeEmpresa: number
    ): Observable<any> {
        // console.log("######## API AUTENTICAR LOGIN INTERNO ADMIN ########")

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            `application/x-www-form-urlencoded`
        );

        let params = new HttpParams();
        params = params.set('grant_type', 'client_credentials');
        params = params.set('client_id', email);
        params = params.set('client_secret', senha);
        params = params.set('code', codigoDeEmpresa.toString());

        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/login-interno-admin`,
                params.toString(),
                { headers }
            )
            .pipe(
                map((r: any) => {
                    AppSettings.armazenarToken(r.access_token);
                    return r.access_token;
                })
            );
    }

    autenticarLoginCliente(
        clientid: string,
        clientsecret: string,
        username: string
    ): Observable<any> {
        // console.log("######## API AUTENTICAR LOGIN CLIENTE ########")

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            `application/x-www-form-urlencoded`
        );

        let params = new HttpParams();
        params = params.set('grant_type', 'client_credentials');
        params = params.set('client_id', clientid);
        params = params.set('client_secret', clientsecret);
        params = params.set('username', username);

        // const url = `${AppSettings.API_ENDPOINT_AUTH}/auth/client-credentials`;

        // return this._httpClient
        //     .post<any>(url, params.toString(), { headers })
        //     .pipe(
        //         map((r: any) => {
        //             AppSettings.armazenarToken(r.access_token);
        //             return r.access_token;
        //         })
        //     );
        return throwError('Método não implementado');
    }

    // autenticarLoginBaseOTK(email: string, senha: string): Observable<any> {
    //     // console.log("######## API AUTENTICAR LOGIN BASE OTK ########")

    //     let headers = new HttpHeaders();
    //     headers = headers.set(
    //         'Content-Type',
    //         `application/x-www-form-urlencoded`
    //     );

    //     let params = new HttpParams();
    //     params = params.set('username', email);
    //     params = params.set('password', senha);

    //     return this._httpClient
    //         .post<any>(
    //             `${AppSettings.API_ENDPOINT_AUTH}/auth/otk`,
    //             params.toString(),
    //             { headers }
    //         )
    //         .pipe(
    //             map((r: any) => {
    //                 AppSettings.armazenarToken(r.access_token);
    //                 return r.access_token;
    //             })
    //         );
    // }

    refreshToken(token: string): Observable<any> {
        console.log('######## REFRESH TOKEN ########');

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);

        // const url = `${AppSettings.API_ENDPOINT_AUTH}/auth/client/refresh-token`;
        const url = `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/cliente/refresh-token`;

        return this._httpClient.post<any>(url, null).pipe(
            tap(() => AppSettings.limparToken()),
            map((r: any) => {
                if (AppSettings.APP_DEV_MODE) {
                    console.log('[DEV] REFRESH TOKEN ->', r);
                }
                AppSettings.armazenarToken(r.access_token);
                return r.access_token;
            })
        );
    }

    private get informacoesDoSistema() {
        return {
            sistema: AppSettings.APP_SISTEMA,
            versao: AppSettings.APP_VERSION_ENVIRONMENT,
        };
    }

    registrarLogin(): Observable<any> {
        let obj = this.informacoesDoSistema;
        if (AppSettings.APP_DEV_MODE) {
            console.log('REGISTRANDO LOGIN', obj);
        }

        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/registrar-login`,
                obj
            )
            .pipe(timeout(10000));
    }
    registrarLogout(): Observable<any> {
        if (AppSettings.APP_DEV_MODE) {
            console.log('REGISTRANDO LOGOUT');
        }

        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/registrar-logout`,
                null
            )
            .pipe(timeout(10000));
    }

    criarNovaConta(
        nomeDaEmpresa: string,
        nomeDoUsuario: string,
        email: string,
        senha: string,
        telefone: string,
        consentimentoDeComunicacao: boolean,
        utmSource: string,
        utmMedium: string,
        utmCampaign: string
    ): Observable<any> {
        return this._httpClient.post(
            `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/criar-conta`,
            {
                nomeDaEmpresa,
                nomeDoUsuario,
                email,
                senha,
                telefone,
                consentimentoDeComunicacao,
                utmSource,
                utmMedium,
                utmCampaign,
            }
        );
    }

    enviarNovaSenha(email: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('email', email);

        return this._httpClient.post(
            `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/enviar-nova-senha`,
            null,
            { params }
        );
    }

    confirmarEmailSenha(
        email: string,
        novaSenha: string,
        token: string
    ): Observable<any> {
        const payload = {
            email,
            novaSenha,
            token,
        };

        return this._httpClient.post<any>(
            `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/confirmar-email`,
            payload
        );
    }

    redefinirSenha(
        email: string,
        novaSenha: string,
        token: string
    ): Observable<any> {
        const payload = {
            email,
            novaSenha,
            token,
        };

        return this._httpClient.post<any>(
            `${AppSettings.API_ENDPOINT_CADASTROS}/sistema/redefinir-senha`,
            payload
        );
    }
}
