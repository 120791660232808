import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxNumberBoxModule, DxPopoverModule } from 'devextreme-angular';
import { LabelGestioModule } from '../label/label.module';
import { NumberboxDisabledLabelGestioComponent } from './numberbox-disabled-label/numberbox-disabled-label.component';
import { NumberboxLabelGestioComponent } from './numberbox-label/cmp-numberbox-label.component';
import { NumberboxSpinLabelGestioComponent } from './numberbox-spin-label/cmp-numberbox-spin-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxNumberBoxModule,
        DxPopoverModule,
        FlexLayoutModule,
        LabelGestioModule,
    ],
    declarations: [
        NumberboxLabelGestioComponent,
        NumberboxDisabledLabelGestioComponent,
        NumberboxSpinLabelGestioComponent,
    ],
    exports: [
        NumberboxLabelGestioComponent,
        NumberboxDisabledLabelGestioComponent,
        NumberboxSpinLabelGestioComponent,
    ],
})
export class NumberboxsComponentsGestioModule {}
