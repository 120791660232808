import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'gestio-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent {
    @Input() data: any[] = [];
    @Input() adminMode: boolean = false;

    @Output() onAddComment = new EventEmitter<any>();
    @Output() onEditComment = new EventEmitter<any>();
}
