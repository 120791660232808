import { Injectable } from '@angular/core';
import { AppSettings } from './app.settings';

import { KeyedCollection } from './core/models/keyed-collection.model';

export enum dictionaryEnum {
    codigoDaClassificacaoAuxiliar = 'codigoDaClassificacaoAuxiliar',
    classificacaoAuxiliar = 'classificacaoAuxiliar',
    descricaoDaClassificacaoAuxiliar = 'descricaoDaClassificacaoAuxiliar',
    observacaoAuxiliar = 'observacaoAuxiliar',
    projetos = 'projetos',
    codigoDoProjeto = 'codigoDoProjeto',
    codigoDoProjetoOrigem = 'codigoDoProjetoOrigem',
    codigoDoProjetoDestino = 'codigoDoProjetoDestino',
    vendedores = 'vendedores',
    codigoDoVendedor = 'codigoDoVendedor',
}

@Injectable({
    providedIn: 'root',
})
export class AppDictionaryService {
    private static _dict: KeyedCollection<string> = null;

    constructor() {
        this.createDictionary();
    }

    private createDictionary() {
        AppDictionaryService._dict = new KeyedCollection<string>();

        AppDictionaryService._dict.Add(
            dictionaryEnum.classificacaoAuxiliar,
            'Informações complementares'
        );
        AppDictionaryService._dict.Add(
            dictionaryEnum.codigoDaClassificacaoAuxiliar,
            'Informação complementar'
        );
        AppDictionaryService._dict.Add(
            dictionaryEnum.descricaoDaClassificacaoAuxiliar,
            'Informação complementar'
        );
        AppDictionaryService._dict.Add(
            dictionaryEnum.observacaoAuxiliar,
            'Observação auxiliar'
        );

        AppDictionaryService._dict.Add(dictionaryEnum.projetos, 'Projetos');
        AppDictionaryService._dict.Add(
            dictionaryEnum.codigoDoProjeto,
            'Projeto'
        );
        AppDictionaryService._dict.Add(
            dictionaryEnum.codigoDoProjetoOrigem,
            'Projeto origem'
        );
        AppDictionaryService._dict.Add(
            dictionaryEnum.codigoDoProjetoDestino,
            'Projeto destino'
        );

        AppDictionaryService._dict.Add(dictionaryEnum.vendedores, 'Vendedores');

        AppDictionaryService._dict.Add(
            dictionaryEnum.codigoDoVendedor,
            'Vendedor'
        );
    }

    displayLabel(item: string, defaultvalue?: string): string {
        const d = defaultvalue ? defaultvalue : item;

        if (!AppDictionaryService._dict) {
            return d;
        }

        const r = AppDictionaryService._dict.Item(item);

        // if (AppSettings.APP_DEV_MODE) {
        //     console.log("[DEV] DICTIONARY DISPLAYLABEL ->", r, d);
        // }

        return r ? r : d;
    }

    addOrUpdate(item: string, value: string): void {
        if (!AppDictionaryService._dict) {
            return;
        }

        AppDictionaryService._dict.Remove(item);
        AppDictionaryService._dict.Add(item, value);

        if (AppSettings.APP_DEV_MODE) {
            console.log('[DEV] DICTIONARY ADDORUPDATE ->', item, value);
        }
    }

    show(): void {
        if (!AppDictionaryService._dict) {
            return;
        }

        if (AppSettings.APP_DEV_MODE) {
            console.log(
                '[DEV] DICTIONARY SHOW ->',
                AppDictionaryService._dict.Values()
            );
        }
    }

    static getDisplayLabel(item: string, defaultvalue?: string): string {
        const d = defaultvalue ? defaultvalue : item;

        if (!AppDictionaryService._dict) {
            return d;
        }

        const r = AppDictionaryService._dict.Item(item);
        return r ? r : d;
    }
}
