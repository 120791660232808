import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { DxPopoverModule, DxSwitchModule } from 'devextreme-angular';
import { CmpSwitchLabelGestioComponent } from './switch-label/cmp-switch-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatTooltipModule,
        DxSwitchModule,
        DxPopoverModule,
    ],
    exports: [CmpSwitchLabelGestioComponent],
    declarations: [CmpSwitchLabelGestioComponent],
})
export class SwitchesComponentsGestioModule {}
