import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-footer-action-button',
    templateUrl: './footer-action-button.component.html',
    styleUrls: ['./footer-action-button.component.scss'],
})
export class FooterActionButtonComponent implements OnInit {
    @Input() text: string = '';
    @Input() disabled: boolean = false;
    @Input() icon: string = '';
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionClick() {
        this.onClick.emit();
    }
}
