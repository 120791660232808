import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { hasRequiredField } from '../../../../app.functions';
import { AppHelper } from '../../../../app.helper';

@Component({
    selector: 'gestio-telefone',
    templateUrl: './telefone.component.html',
    styleUrls: ['./telefone.component.scss'],
})
export class TelefoneGestioComponent implements OnInit {
    @Input() label: string = 'Telefone';
    @Input() changeLabel: boolean = true;

    @Input() width: string = '100%';
    @Input() height: string = '100%';
    @Input() widthHelp: string = '300px';

    @Input() help: string = null;
    @Input() copyButton: boolean = true;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';

    @Input() mask: string = null;
    @Input() useMaskedValue: boolean = true;
    @Input() showClearButton: boolean = true;
    @Input() upper: boolean = false;

    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Telefone inválido.`;
    @Input() maskInvalidMessage: string = `Formato inválido.`;

    @Input() focus$: Subject<boolean> = new Subject<boolean>();

    @Output() onValidMask = new EventEmitter<any>();
    @Output() onClearClick = new EventEmitter<any>();
    @Output() onEnterKeyEvent = new EventEmitter<any>();

    @ViewChild(DxTextBoxComponent) textBox: DxTextBoxComponent;

    popoverVisible: boolean = false;
    upperCaseValue: string = null;

    private focusSubscription: Subscription;

    ngOnInit() {
        this.focusSubscription = this.focus$
            .pipe(filter((b: boolean) => b == true))
            .subscribe(() => {
                setTimeout(() => {
                    if (this.textBox && this.textBox.instance) {
                        this.textBox.instance.focus();
                    }
                }, 0);
            });

        if (this.upper) {
            this.control.valueChanges.subscribe(() => {
                this.upperCaseValue = this.control.value;
                AppHelper.setCtrlValue(
                    this.control,
                    this.control.value.toUpperCase()
                );
            });
        }
    }

    ngOnDestroy() {
        this.focusSubscription.unsubscribe();
    }

    ngOnChanges() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (!this.changeLabel) {
            return this.label;
        }

        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    get validationStatus() {
        return this.control.invalid && this.control.touched
            ? 'invalid'
            : 'valid';
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                if (this.errorInvalid) {
                    return { message: this.errorInvalid };
                } else {
                    return { message: `Inválido.` };
                }
            }
        }
    }

    actionOnEnterKey(e: any) {
        this.onEnterKeyEvent.emit(this.control.value);
    }

    actionValueChanged(e: any) {
        if (e && e.value && this.mask && this.textBox) {
            if (this.textBox.isValid) {
                this.onValidMask.next(e.value);
            } else {
                this.control.setErrors({ invalidMask: true });
            }
        }
    }

    actionKeyUp(e: any) {
        if (this._isSpecialKey(e)) {
            return;
        }

        if (!this.textBox) {
            return;
        }

        let r = this._phoneFormat(this.textBox.text);
        this.textBox.instance.option('value', r);
    }

    private _getOnlyNumbers(e: any) {
        let r = e.replace(/[^0-9]+/g, '');
        return r;
    }

    private _phoneFormat(v): string {
        var r = this._getOnlyNumbers(v);
        if (!r) {
            return r;
        }

        if (r && r.length == 10) {
            r =
                '(' +
                r.substr(0, 2) +
                ') ' +
                r.substr(2, 4) +
                '-' +
                r.substr(6);
            return r;
        }

        if (r && r.length >= 11) {
            r =
                '(' +
                r.substr(0, 2) +
                ') ' +
                r.substr(2, 5) +
                '-' +
                r.substr(7, 4);
            return r;
        }

        if (r && r.length >= 2) {
            r = '(' + r.substr(0, 2) + ') ' + r.substr(2);
            return r;
        }

        return r;
    }

    private _isSpecialKey(e: any): boolean {
        var charCode = e.which ? e.which : e.keyCode;
        return (
            charCode == 8 || // Tab
            charCode == 9 || //
            charCode == 13 || // Enter
            charCode == 16 || // Shift
            charCode == 27 || // Esc
            charCode == 35 || // Esc
            charCode == 36 || // Home
            charCode == 37 || // Esquerda
            charCode == 39 || // Direita
            charCode == 144
        ); // Numlock
    }

    actionContentReady(e: any) {
        const selector = e.element.querySelector('.dx-icon-clear');
        if (selector) {
            selector.addEventListener('click', () => this.onClearClick.emit());
        }
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
