import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[scrollTo]',
})
export class ScrollToDirective {
    @Input('scrollTo') targetId!: string;
    @Input() fragmentActive!: string;
    @Input() offset: number = 64;
    @Input() activeClass: string = 'active-link';

    constructor() {}

    ngOnInit() {}

    @HostListener('click') onClick() {
        if (this.targetId) {
            const targetElement = document.getElementById(this.targetId);

            if (targetElement) {
                const parentScrollable =
                    this.getScrollableParent(targetElement);
                const targetPosition =
                    targetElement.offsetTop -
                    (parentScrollable ? parentScrollable.offsetTop : 0) -
                    this.offset;

                if (parentScrollable) {
                    this.scrollToSmooth(parentScrollable, targetPosition);
                } else {
                    this.scrollToSmooth(window, targetPosition);
                }
            }
        }
    }

    private getScrollableParent(element: HTMLElement): HTMLElement | null {
        let parent = element.parentElement;
        while (parent) {
            const overflowY = window.getComputedStyle(parent).overflowY;
            if (overflowY === 'auto' || overflowY === 'scroll') {
                return parent;
            }
            parent = parent.parentElement;
        }
        return null;
    }

    private scrollToSmooth(scrollable: HTMLElement | Window, top: number) {
        if ('scrollTo' in scrollable) {
            (scrollable as any).scrollTo({
                top: top,
                behavior: 'smooth',
            });
        } else {
            (scrollable as any).scrollTop = top;
        }
    }
}
