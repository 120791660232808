import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewContainerRef,
} from '@angular/core';
import { take } from 'rxjs/operators/take';
import { AppFilesService } from '../../../app-files.service';

import { AppSettings } from '../../../app.settings';
import { ConfirmService } from '../../../shared-services/confirm/confirm.service';
import { MessageService } from '../../../shared-services/message/message.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { StateModeEnum } from '../../../app.enums';
import { AppHelper } from '../../../app.helper';

interface Data {
    $id: string;
    codigoDoExercicio: number;
    id: string;
    idProduto: 0;
    nomeDoArquivo: string;
    numeroDaMovimentacao: number;
    numeroDoPedido: number;
    observacao?: string;
    url: string;
}

@Component({
    selector: 'gestio-attach-files',
    templateUrl: './attach-files.component.html',
    styleUrls: ['./attach-files.component.scss'],
})
export class AttachFilesComponent implements OnInit {
    @Input() numero: number = 0;
    @Input() codigoDoExercicio: number = 0;
    @Input() numeroDaMovimentacao: number = 0;
    @Input() urlUpload: string = '';
    @Input() service: any;
    @Input() visiblePortal: boolean = false;
    @Input() hasObservacaoForm: boolean = false;

    @Output() updateEvent = new EventEmitter<any>();

    isFormModalVisible = false;
    isDropZoneActive = false;
    imageSource = '';
    textVisible = true;
    progressVisible = false;
    progressValue = 0;
    uploaderId: string;

    form: FormGroup;
    observacao: FormControl;
    focus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    state$: BehaviorSubject<StateModeEnum> = new BehaviorSubject<StateModeEnum>(
        StateModeEnum.edit
    );

    data: Data[] = [];
    dataSend: Data;
    files: any[] = [];

    maxSize = this.appFilesService.maxFileSize();
    maxFileSizeMessage: string;
    allowedFileExtensions = this.appFilesService.allowedFileExtensions();
    allowedFileExtensionsFmt = this.appFilesService.allowedFileExtensionsFmt;

    constructor(
        private message: MessageService,
        private appFilesService: AppFilesService,
        private viewContainerRef: ViewContainerRef,
        private confirm: ConfirmService
    ) {
        this._configurarForm();

        this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
        this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onUploadStarted = this.onUploadStarted.bind(this);
    }

    ngOnInit() {
        this.uploaderId = this.uploaderId =
            'gestio-file-uploader-' + Math.random().toString(36).substr(2, 9);
    }

    set(arquivo: Data): void {
        this.dataSend = arquivo;

        if (this.dataSend && this.dataSend.observacao !== undefined) {
            AppHelper.setCtrlValue(this.observacao, this.dataSend.observacao);
        } else {
            AppHelper.setCtrlValue(this.observacao, '');
        }
    }

    private _configurarForm() {
        this.observacao = new FormControl(null, [Validators.maxLength(300)]);

        this.form = new FormGroup({
            observacao: this.observacao,
        });

        this.form.statusChanges.subscribe((r) => {
            let b = r == 'VALID';
            if (this.valid$.value != b) {
                this.valid$.next(b);
            }
        });
    }

    ngOnDestroy() {}

    ngOnChanges() {
        this.carregar();
        this.carregarPedido();
    }

    actionRefresh() {
        this.carregar();
        this.carregarPedido();
    }

    carregar(): void {
        this.data = [];
        if (!this.numero) {
            return;
        }
        this.service
            .obterArquivos(this.numero)
            .pipe(take(1))
            .subscribe((r) => {
                this.data = r;
            });
    }

    carregarPedido(): void {
        this.data = [];
        if (!this.codigoDoExercicio || !this.numeroDaMovimentacao) {
            return;
        }
        this.service
            .obterArquivos(this.codigoDoExercicio, this.numeroDaMovimentacao)
            .pipe(take(1))
            .subscribe((r) => {
                this.data = r;
            });
    }

    downloadAnexoServidor(arquivo: any): void {
        if (!arquivo || !arquivo.id) {
            return;
        }

        this.service
            .download(arquivo.id)
            .pipe(take(1))
            .subscribe(
                (r) => {},
                (e) => {
                    this.message.errorMessageOnlyText(
                        'Não foi possível realizar o download do arquivo.'
                    );
                }
            );
    }

    visualizarAnexo(url: string): void {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
    }

    excluirAnexo(arquivo: any): void {
        this.confirm
            .message(
                `Confirma a exclusão do arquivo?`,
                `<b>${arquivo.nomeDoArquivo}</b><br>&nbsp;`,
                this.viewContainerRef
            )
            .subscribe((r: boolean) => {
                if (r) {
                    this.service
                        .excluirArquivo(arquivo.id)
                        .pipe(take(1))
                        .subscribe({
                            next: () => {
                                this.data = this.data.filter(
                                    (item) => item.id !== arquivo.id
                                );
                                this.message.successMessage(
                                    'Arquivo excluído com sucesso.'
                                );
                            },
                        });
                }
            });
    }

    onValueChanged(e: any) {
        if (e.value && e.value.length > 0) {
            const file = e.value[0];
            if (file.size > this.maxSize) {
                this.maxFileSizeMessage =
                    'O arquivo não pôde ser anexado, pois seu tamanho excede o limite de 5MB.';
                this.files = [];
            } else {
                this.maxFileSizeMessage = '';
            }
        }
    }

    onDropZoneEnter({ component, dropZoneElement }) {
        if (dropZoneElement.id === this.uploaderId) {
            const allowedFileExtensions = component.option(
                'allowedFileExtensions'
            );

            const isValidFileExtension = allowedFileExtensions;

            if (isValidFileExtension) {
                this.isDropZoneActive = true;
            }
        }
    }

    onDropZoneLeave(e) {
        if (e.dropZoneElement.id === this.uploaderId) {
            this.isDropZoneActive = false;
        }
    }

    cleanUploader() {
        this.files = [];
    }

    actionUploadError(event: any) {
        const errorJson = JSON.parse(event.error.responseText);
        const [error] = errorJson.errors;
        if (error && error.value) {
            this.message.errorMessageOnlyText(error.value);
        }
    }

    actionUpdateEvent() {
        this.updateEvent.emit(true);
        this.cleanUploader();
    }

    actionUpdateFlag(event: any) {
        const obj = Object.assign(event);
        if (!obj || !obj.id) {
            return;
        }
        obj.visivelNoPortal = !obj.visivelNoPortal;
        this.service
            .alterarInformacoesDoArquivo(obj)
            .subscribe(() => this.actionUpdateEvent());
    }

    onUploaded(e) {
        const file = e.file;

        const fileReader = new FileReader();
        fileReader.onload = () => {
            this.isDropZoneActive = false;
            this.imageSource = fileReader.result as string;
        };
        fileReader.readAsDataURL(file);
        this.textVisible = false;
        this.progressVisible = false;
        this.progressValue = 0;

        this.carregar();
        this.carregarPedido();
    }

    onUploadStarted() {
        this.imageSource = '';
        this.progressVisible = true;
    }

    onProgress(e) {
        this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
    }

    actionBackgroundColorArchiveExtension(nomeDoArquivo: string): string {
        if (!nomeDoArquivo) {
            return '#58657A';
        }

        const extension = nomeDoArquivo.split('.').pop().toLowerCase();

        const backgroundColor: { [archiveExtension: string]: string } = {
            pdf: '#DF452D',
            png: '#FEC84B',
            jpg: '#FEC84B',
            jpeg: '#FEC84B',
            doc: '#175CD3',
            docx: '#175CD3',
            xls: '#039855',
            xlsx: '#039855',
            ppt: '#DC6803',
            pptx: '#DC6803',
            xml: '#58657A',
        };

        return backgroundColor[extension] || '#58657A';
    }

    get urlHeaders(): any {
        var header = {
            ['Authorization']: `Bearer ` + AppSettings.recuperarToken(),
            ['Pragma']: 'no-cache',
        };
        return header;
    }

    actionOpenFormModal(arquivo: any) {
        this.isFormModalVisible = !this.isFormModalVisible;

        this.set(arquivo);
    }

    actionFechar(b: boolean = false) {
        this.isFormModalVisible = false;
    }

    actionSalvar() {
        const obj = Object.assign({}, this.dataSend, this.form.value);

        if (!obj || !obj.id) {
            console.error('Arquivo inválido ou sem ID');
            return;
        }

        this.service.alterarInformacoesDoArquivo(obj).subscribe(
            () => {
                this.message.successMessage(
                    'Observação do anexo alterada com sucesso!'
                );
                this.actionRefresh();
                this.actionFechar(false);
            },
            () => {
                this.message.errorMessage(
                    'Não foi possível alterar observação do anexo (Tente novamente mais tarde).'
                );
            }
        );
    }
}
