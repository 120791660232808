import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppHelper } from '../../app.helper';
import { ContratacaoRelatoriosApiService } from './store/contratacao-relatorios-api.service';

@Component({
    selector: 'app-contratacao-relatorios-dialog',
    templateUrl: './contratacao-relatorios-dialog.component.html',
    styleUrls: ['./contratacao-relatorios-dialog.component.scss'],
})
export class ContratacaoRelatoriosDialogComponent implements OnInit {
    public title: string;
    public message: string;

    detalheRelatorios: FormControl;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoRelatoriosDialogComponent>,
        private apiService: ContratacaoRelatoriosApiService
    ) {}

    ngOnInit() {
        this.detalheRelatorios = new FormControl('', Validators.required);

        this.form = new FormGroup({
            textoLivre: this.detalheRelatorios,
        });
    }

    confirmar(): void {
        if (this.form.invalid) {
            return;
        }

        const texto = this.detalheRelatorios.value;

        this.apiService.solicitarRelatorios(texto).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
