import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-danger-button',
    templateUrl: './danger-button.component.html',
    styleUrls: ['./danger-button.component.scss'],
})
export class DangerButtonComponent implements OnInit {
    @Input() text: string = 'Excluir';
    @Input() enabled: boolean = true;
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionClick() {
        this.onClick.emit();
    }
}
