import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { AppRoutingService } from '../../app-routing.service';
import { AuthService } from '../services/auth.service';
import { AppSettings } from '../../app.settings';

@Injectable({
    providedIn: 'root',
})
export class CheckoutGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private _routingService: AppRoutingService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (!this.authService.checkout$.value) {
            if (
                this.authService.ambienteDemonstracao$.value &&
                AppSettings.APP_DEV_MODE
            ) {
                this._routingService.checkoutSelecionarPlano();
                return false;
            } else {
                this._routingService.sistemaAvisoSemPermissao();
                return false;
            }
        } else return true;
    }
}
