import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ContratacaoIntegracoesApiService } from './store/contratacao-integracoes-api.service';

@Component({
    selector: 'app-contratacao-integracoes-dialog',
    templateUrl: './contratacao-integracoes-dialog.component.html',
    styleUrls: ['./contratacao-integracoes-dialog.component.scss'],
})
export class ContratacaoIntegracoesDialogComponent implements OnInit {
    public title: string;
    public message: string;

    detalheIntegracao: FormControl;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoIntegracoesDialogComponent>,
        private apiService: ContratacaoIntegracoesApiService
    ) {}

    ngOnInit() {
        this.detalheIntegracao = new FormControl('', Validators.required);

        this.form = new FormGroup({
            textoLivre: this.detalheIntegracao,
        });
    }

    confirmar(): void {
        if (this.form.invalid) {
            return;
        }

        const textoLivre = this.detalheIntegracao.value;

        this.apiService.contratarIntegracao(textoLivre).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
