import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'gestio-cmp-checkbox-label',
    templateUrl: './cmp-checkbox-label.component.html',
    styleUrls: ['./cmp-checkbox-label.component.scss'],
})
export class CmpCheckboxLabelGestioComponent implements OnInit {
    @Input() label: string = null;
    @Input() disabled: boolean = false;
    @Input() iconSize: string = '16px';
    @Input() control: FormControl = new FormControl(null, []);
    @Input() help: string = null;
    @Input() textHelp: string = null;
    @Input() placeholder: string = '';

    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() noMarginBottom: boolean = false;

    popoverVisible: boolean;

    showEvent = {
        name: 'mouseenter',
        delay: 70,
    };

    hideEvent = {
        name: 'mouseleave',
        delay: 70,
    };

    constructor() {}

    ngOnInit() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        return this.label;
    }

    get sanitizedLabel(): string {
        if (!this.label) return '';

        return this.label
            .toLowerCase() // transforma em minúsculas
            .normalize('NFD') // separa letras dos acentos
            .replace(/[\u0300-\u036f]/g, '') // remove os acentos
            .replace(/[^a-z0-9]/g, ''); // remove espaços e caracteres especiais
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    onContentReady(event) {
        var contentElement = event.component.content();
        contentElement.style.padding = '0px';
    }
}
