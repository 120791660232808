import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'gestio-metrics',
    templateUrl: './metrics.component.html',
    styleUrls: ['./metrics.component.scss'],
})
export class MetricsGestioComponent implements OnInit {
    @Input() isLoading: boolean = false;
    @Input() dangerBadge: boolean = false;
    @Input() infoBadge: boolean = false;

    constructor() {}

    ngOnInit() {}
}
