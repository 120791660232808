import { ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StateModeEnum } from 'src/app/app.enums';

@Component({
    selector: 'gestio-cmp-footer-bar',
    templateUrl: './cmp-footer-bar.component.html',
    styleUrls: ['./cmp-footer-bar.component.scss'],
})
export class CmpFooterBarGestioComponent {
    @Input() state$: BehaviorSubject<StateModeEnum>;
    @Input() titleElementRef!: ElementRef;

    isStateVisible: boolean = true;
    showFooterBar: boolean = false;

    private observer!: IntersectionObserver;
    private stateSubscription: Subscription;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.state$) {
            this.stateSubscription = this.state$.subscribe((r) => {
                this.isStateVisible =
                    r == StateModeEnum.insert || r == StateModeEnum.edit;
            });
        }
    }

    ngAfterViewInit(): void {
        if (!this.titleElementRef) {
            this.showFooterBar = true;
            return;
        } else {
            this.observer = new IntersectionObserver(
                ([entry]) => {
                    const isOutOfView =
                        !entry.isIntersecting &&
                        entry.boundingClientRect.height >= 36;
                    if (this.showFooterBar !== isOutOfView) {
                        this.showFooterBar = isOutOfView;
                        this.cdr.detectChanges();
                    }
                },
                { threshold: 0 }
            );

            this.observer.observe(this.titleElementRef.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.state$) {
            this.stateSubscription.unsubscribe();
        }

        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
