import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { hasRequiredField } from '../../../../app.functions';
import { AppHelper } from '../../../../app.helper';
import { MessageService } from '../../../../shared-services/message/message.service';
import { DxNumberBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'gestio-cmp-numberbox-spin-label',
    templateUrl: './cmp-numberbox-spin-label.component.html',
    styleUrls: ['./cmp-numberbox-spin-label.component.scss'],
})
export class NumberboxSpinLabelGestioComponent implements OnInit {
    @Input() label: string = null;
    @Input() help: string = null;
    @Input() copyButton: boolean = false;
    @Input() control: FormControl = new FormControl('', []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() showClearButton: boolean = false;
    @Input() min: number = null;
    @Input() max: number = null;
    @Input() readOnly: boolean = false;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorGt: string = null;
    @Input() errorMin: string = null;
    @Input() errorMax: string = null;
    @Input() acceptNull: boolean = false;
    @Input() focus$: Subject<boolean> = new Subject<boolean>();
    @Input() value: number = 0;

    @Output() onEnterKeyEvent = new EventEmitter<any>();

    @Output() onClearClick = new EventEmitter<any>();

    @ViewChild(DxNumberBoxComponent) numberBox: DxNumberBoxComponent;

    popoverVisible: boolean;

    constructor(private _message: MessageService) {}

    ngOnInit() {
        if (this.acceptNull) {
        } else {
            if (
                this.control &&
                (this.control.value == null || this.control.value == undefined)
            ) {
                AppHelper.setCtrlValue(this.control, 0);
            }
        }

        this.focus$.pipe(take(1)).subscribe(() => {
            setTimeout(() => {
                if (this.numberBox && this.numberBox.instance) {
                    this.numberBox.instance.focus();
                }
            }, 800);
        });
    }

    ngOnChanges() {

    }

    ngOnDestroy() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.gt) {
                return {
                    message: this.errorGt
                        ? this.errorGt
                        : `Inválido. Deve ser maior ou igual que ${AppHelper.numberToFmtStr(
                              this.control.errors.requiredValue
                          )}.`,
                };
            } else if (this.control.errors.min) {
                return {
                    message: this.errorMin
                        ? this.errorMin
                        : `Deve ser maior ou igual que ${AppHelper.numberToFmtStr(
                              this.control.errors.requiredValue
                          )}.`,
                };
            } else if (this.control.errors.max) {
                return {
                    message: this.errorMax
                        ? this.errorMax
                        : `Deve ser menor ou igual que ${AppHelper.numberToFmtStr(
                              this.control.errors.requiredValue
                          )}.`,
                };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    actionContentReady(e: any) {
        const selector = e.element.querySelector('.dx-icon-clear');
        if (selector) {
            selector.addEventListener('click', () => this.onClearClick.emit());
        }
    }

    actionValueChanged(e: any) {
        if (e.value === null) {
            if (this.acceptNull) {
            } else {
                e.component.option('value', 0);
            }
        }
    }

    actionOnClearClick(e: any) {
        this.onClearClick.next(e);
    }

    actionOnEnterKey(e: any) {
        this.onEnterKeyEvent.emit(this.control.value);
    }

    actionOnCopyButtonClick() {
        if (!this.control.value) {
            return;
        }

        this.copyButton = false;

        AppHelper.copyToClipboard(this.control.value);

        this._message
            .simpleMessage('Conteúdo copiado.', 500)
            .pipe(take(1))
            .subscribe(() => {
                this.copyButton = true;
            });
    }

    get showCopyButton(): boolean {
        if (!this.control || !this.control.value) {
            return false;
        }

        if (this.control.disabled) {
            return true;
        }

        return false;
    }

    onFocusIn(e: any) {
        let inputEl = e.element.querySelector('.dx-texteditor-input');
        if (inputEl) {
            inputEl.selectionStart = 0;
            inputEl.selectionEnd = inputEl.value.length;
        }
    }
}
