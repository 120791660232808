import { Injectable } from '@angular/core';

import { AuthService } from './auth/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AppFilesService {
    constructor(private _authService: AuthService) {}

    maxFileSize(): number {
        return 5000000; // 5MB
    }

    allowedFileExtensions(): string[] {
        if (this._authService.especificoMcSupply$.value) {
            return [
                '.jpg',
                '.jpeg',
                '.gif',
                '.png',
                '.jfif',
                '.docx',
                '.xlsx',
                '.pptx',
                '.doc',
                '.xls',
                '.ppt',
                '.msg',
                '.pdf',
                '.xml',
                '.txt',
                '.dxf',
                '.step',
                '.slxs',
                '.zip',
                '.rar',
            ];
        }

        return [
            '.jpg',
            '.jpeg',
            '.gif',
            '.png',
            '.jfif',
            '.docx',
            '.xlsx',
            '.pptx',
            '.doc',
            '.xls',
            '.ppt',
            '.msg',
            '.pdf',
            '.xml',
            '.txt',
        ];
    }

    // LISTA QUE APARECE NO FRONT
    allowedFileExtensionsList(): string[] {
        if (this._authService.especificoMcSupply$.value) {
            return [
                '.jpg',
                '.jpeg',
                '.gif',
                '.png',
                '.jfif',
                '.docx',
                '.xlsx',
                '.pptx',
                '.doc',
                '.xls',
                '.ppt',
                '.msg',
                '.pdf',
                '.xml',
                '.txt',
                '.dxf',
                '.step',
                '.slxs',
                '.zip',
                '.rar',
            ];
        }

        return [
            '.jpg',
            '.jpeg',
            '.gif',
            '.png',
            '.docx',
            '.xlsx',
            '.pdf',
            '.xml',
        ];
    }

    get allowedFileExtensionsFmt(): string {
        const extensions = this.allowedFileExtensionsList();

        return extensions.join(', ');
    }
}
