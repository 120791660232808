import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { AppRoutingService } from '../../app-routing.service';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ContratarAdicionaisGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private _routingService: AppRoutingService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.authService.ambienteDemonstracao$.value) {
            this._routingService.sistemaAvisoSemPermissao();
            return false;
        }

        return true;
    }
}
