import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material';
import { DxFileUploaderModule, DxProgressBarModule } from 'devextreme-angular';
import { ButtonsComponentsGestioModule } from '../buttons/buttons-components.module';
import { FormModalModule } from '../modal/form/form-modal.module';
import { TextareasComponentsGestioModule } from '../textarea/textareas-components.module';
import { AttachFilesComponent } from './attach-files.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        DxProgressBarModule,
        DxFileUploaderModule,
        FormModalModule,
        MatTooltipModule,
        TextareasComponentsGestioModule,
        ButtonsComponentsGestioModule,
    ],
    declarations: [AttachFilesComponent],
    exports: [AttachFilesComponent],
})
export class AttachFilesModule {}
