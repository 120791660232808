import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxPopoverModule, DxTextAreaModule } from 'devextreme-angular';
import { TextareaLabelGestioComponent } from './textarea-label/textarea-label.component';
import { LabelGestioModule } from '../label/label.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxTextAreaModule,
        DxPopoverModule,
        FlexLayoutModule,
        LabelGestioModule
    ],
    declarations: [TextareaLabelGestioComponent],
    exports: [TextareaLabelGestioComponent],
})
export class TextareasComponentsGestioModule {}
