import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gestio-cmp-detail-section',
    templateUrl: './cmp-detail-section.component.html',
    styleUrls: ['./cmp-detail-section.component.scss'],
})
export class CmpDetailSectionGestioComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
