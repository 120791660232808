import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxPopoverModule, DxTextBoxModule } from 'devextreme-angular';

import { LabelGestioModule } from '../label/label.module';
import { GestioTextboxGenerateProductCodeComponent } from './gestio-textbox-generate-product-code.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxPopoverModule,
        LabelGestioModule,
    ],
    declarations: [GestioTextboxGenerateProductCodeComponent],
    exports: [GestioTextboxGenerateProductCodeComponent],
})
export class TextboxGenerateProductCodeGestioModule {}
