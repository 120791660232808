import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { DxPopoverModule, DxRadioGroupModule } from 'devextreme-angular';
import { CmpRadioGroupLabelGestioComponent } from './radio-group-label/cmp-radio-group-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatTooltipModule,
        DxRadioGroupModule,
        DxPopoverModule,
    ],
    exports: [CmpRadioGroupLabelGestioComponent],
    declarations: [
        CmpRadioGroupLabelGestioComponent,
    ],
})
export class RadioGroupComponentsGestioModule {}
