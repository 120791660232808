import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-comments-option-button',
    templateUrl: './comments-option-button.component.html',
    styleUrls: ['./comments-option-button.component.scss'],
})
export class CommentsOptionButtonComponent implements OnInit {
    @Input() text: string = 'Mais ações';
    @Input() icon: string = 'keyboard_arrow_down';
    @Input() list: { text: string; isDanger?: boolean }[] = [];
    @Input() gridName: string;
    @Output() onClick = new EventEmitter<any>();

    isOpen: boolean = false;

    constructor() {}

    ngOnInit() {}

    onContentReady(e) {
        if (!e) return;
        e.component.content().style.padding = '8px';
    }

    onHidden() {
        this.isOpen = false;
    }

    actionOpen() {
        if (this.list) {
            this.isOpen = !this.isOpen;
        }
    }

    actionClick(button: string) {
        if (!button) return;
        this.onClick.emit(button);
    }

    actionClickOrNavigate(button): void {
        this.isOpen = false;
        if (button.link) {
            window.open(button.link, '_blank');
        } else {
            this.onClick.emit(button);
        }
    }
}
