import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'gestio-form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit {
    @Input() title: string | null = null;
    @Input() subTitle: string | null = null;
    @Input() isVisible: boolean = false;
    @Input() width: string = '520px';

    @Output() onClose = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    onContentReady(event) {
        var contentElement = event.component.content();
        contentElement.style.padding = '0px';
        contentElement.style.boxShadow = 'none';
    }

    actionFechar() {
        this.onClose.emit();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.onClose.emit();
        }
    }
}
