import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';

@Component({
    selector: 'gestio-tabs-dashboard',
    templateUrl: './tabs-dashboard.component.html',
    styleUrls: ['./tabs-dashboard.component.scss'],
})
export class TabsDashboardComponent implements OnInit {
    @Input() tabs: any[] = [];
    @Input() selectedIndex: number = 0;
    @Input() selectedDropDownListItem: number = null;
    @Output() onItemClick = new EventEmitter<any>();

    isOpen: boolean = false;

    constructor() {}

    ngOnInit() {
        this.tabs = this.tabs.filter((tab) => tab.isVisible);
    }

    ngOnChanges() {}

    handleDropdownClick(event: Event, index: number, data: any) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }

    onHidden() {
        this.isOpen = false;
    }

    onContentReady(e) {
        if (!e) return;
        e.component.content().style.padding = '8px';
    }

    actionSelectDropdownItem(item, dropDownItem) {
        if (!dropDownItem) return;

        if (this.selectedDropDownListItem !== dropDownItem.id) {
            this.onItemClick.emit({
                itemData: {
                    id: 0,
                    value: 'compras',
                    dropdownitem: dropDownItem.value,
                },
            });

            this.selectedDropDownListItem = dropDownItem.id;
        }
    }

    actionItemClick(event) {
        if (event && event.itemData.dropdown) return;
        else {
            this.selectedDropDownListItem = null;
        }
        this.onItemClick.emit(event);
    }
}
