import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewContainerRef,
} from '@angular/core';
import { take } from 'rxjs/operators/take';
import { AppFilesService } from '../../../app-files.service';

import { ConfirmService } from '../../../shared-services/confirm/confirm.service';
import { AppSettings } from '../../../app.settings';
import { MessageService } from '../../../shared-services/message/message.service';

@Component({
    selector: 'gestio-attach-files-product',
    templateUrl: './attach-files-product.component.html',
    styleUrls: ['./attach-files-product.component.scss'],
})
export class AttachFilesProductComponent implements OnInit {
    @Input() numero: number = 0;
    @Input() idProduto: number = 0;
    @Input() codigoDoExercicio: number = 0;
    @Input() numeroDaMovimentacao: number = 0;

    @Input() urlUpload: string = '';
    @Input() service: any;
    @Input() visiblePortal: boolean = false;

    @Output() updateEvent = new EventEmitter<any>();

    isDropZoneActive = false;
    imageSource = '';
    textVisible = true;
    progressVisible = false;
    progressValue = 0;

    data: any[] = [];
    files: any[] = [];

    maxSize = this.appFilesService.maxFileSize();
    allowedFileExtensions = this.appFilesService.allowedFileExtensions();
    allowedFileExtensionsFmt = this.appFilesService.allowedFileExtensionsFmt;
    maxFileSizeMessage: string;

    constructor(
        private message: MessageService,
        private appFilesService: AppFilesService,
        private viewContainerRef: ViewContainerRef,
        private confirm: ConfirmService
    ) {
        this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
        this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onUploadStarted = this.onUploadStarted.bind(this);
    }

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {
        this.carregarAnexoPorProduto();
    }

    actionRefresh() {
        this.carregarAnexoPorProduto();
    }

    carregarAnexoPorProduto(): void {
        this.data = [];
        if (!this.numero || !this.idProduto) {
            return;
        }
        this.service
            .obterArquivosPorProduto(this.numero, this.idProduto)
            .pipe(take(1))
            .subscribe((r) => {
                this.data = r;
                // console.log('ARQUIVOS ANEXADOS ->', this.data);
            });
    }

    downloadAnexoServidor(arquivo: any): void {
        if (!arquivo || !arquivo.id) {
            return;
        }

        this.service
            .download(arquivo.id)
            .pipe(take(1))
            .subscribe(
                (r) => {},
                (e) => {
                    this.message.errorMessageOnlyText(
                        'Não foi possível realizar o download do arquivo.'
                    );
                }
            );
    }

    visualizarAnexo(url: string): void {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
    }

    excluirAnexo(arquivo: any): void {
        this.confirm
            .message(
                `Confirma a exclusão do arquivo?`,
                `<b>${arquivo.nomeDoArquivo}</b><br>&nbsp;`,
                this.viewContainerRef
            )
            .subscribe((r: boolean) => {
                if (r) {
                    this.service
                        .excluirArquivo(arquivo.id)
                        .pipe(take(1))
                        .subscribe({
                            next: () => {
                                this.data = this.data.filter(
                                    (item) => item.id !== arquivo.id
                                );
                                this.message.successMessage(
                                    'Arquivo excluído com sucesso.'
                                );
                            },
                        });
                }
            });
    }

    onValueChanged(e: any) {
        if (e.value && e.value.length > 0) {
            const file = e.value[0];
            if (file.size > this.maxSize) {
                this.maxFileSizeMessage =
                    'O arquivo não pôde ser anexado, pois seu tamanho excede o limite de 5MB.';
                this.files = [];
            } else {
                this.maxFileSizeMessage = '';
            }
        }
    }

    onDropZoneEnter({ component, dropZoneElement }) {
        if (dropZoneElement.id === 'gestio-file-uploader-product') {
            const allowedFileExtensions = component.option(
                'allowedFileExtensions'
            );

            const isValidFileExtension = allowedFileExtensions;

            if (isValidFileExtension) {
                this.isDropZoneActive = true;
            }
        }
    }

    onDropZoneLeave(e) {
        if (e.dropZoneElement.id === 'gestio-file-uploader-product') {
            this.isDropZoneActive = false;
        }
    }

    cleanUploader() {
        this.files = [];
    }

    actionUploadError(event: any) {
        const errorJson = JSON.parse(event.error.responseText);
        const [error] = errorJson.errors;
        if (error && error.value) {
            this.message.errorMessageOnlyText(error.value);
        }
    }

    actionUpdateEvent() {
        this.updateEvent.emit(true);
        this.cleanUploader();
    }

    actionUpdateFlag(event: any) {
        const obj = Object.assign(event);
        if (!obj || !obj.id) {
            return;
        }
        obj.visivelNoPortal = !obj.visivelNoPortal;
        this.service
            .alterarInformacoesDoArquivo(obj)
            .subscribe(() => this.actionUpdateEvent());
    }

    onUploaded(e) {
        const file = e.file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            this.isDropZoneActive = false;
            this.imageSource = fileReader.result as string;
        };
        fileReader.readAsDataURL(file);
        this.textVisible = false;
        this.progressVisible = false;
        this.progressValue = 0;

        this.carregarAnexoPorProduto();
    }

    onUploadStarted() {
        this.imageSource = '';
        this.progressVisible = true;
    }

    onProgress(e) {
        this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
    }

    actionBackgroundColorArchiveExtension(nomeDoArquivo: string): string {
        if (!nomeDoArquivo) {
            return '#58657A';
        }

        const extension = nomeDoArquivo.split('.').pop().toLowerCase();

        const backgroundColor: { [archiveExtension: string]: string } = {
            pdf: '#DF452D',
            png: '#FEC84B',
            jpg: '#FEC84B',
            jpeg: '#FEC84B',
            doc: '#175CD3',
            docx: '#175CD3',
            xls: '#039855',
            xlsx: '#039855',
            ppt: '#DC6803',
            pptx: '#DC6803',
            xml: '#58657A',
        };

        return backgroundColor[extension] || '#58657A';
    }

    get urlHeaders(): any {
        var header = {
            ['Authorization']: `Bearer ` + AppSettings.recuperarToken(),
            ['Pragma']: 'no-cache',
        };
        return header;
    }
}
