import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxPopupModule
} from 'devextreme-angular';
import { FormModalComponent } from './form-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DxPopupModule,

    ],
    declarations: [
        FormModalComponent,
    ],
    exports: [FormModalComponent],
})
export class FormModalModule {}
