export enum StateModeEnum {
    undefined = 0,

    insert = 1,
    edit = 2,
    browse = 4,
    selected = 5,
    modified = 6,
    removed = 7,
}

export enum UnidadeMedidaEnum {
    QTDE = 'QTDE',
    METRO = 'M',
    CENTIMETRO = 'CM',
}

export enum GridFileNameExportEnum {
    GESTIO_AGENCIA = '[Gestio] Agências',
    GESTIO_AJUSTE_ESTOQUE = '[Gestio] Ajustes de estoque',
    GESTIO_ALMOXARIFADO = '[Gestio] Almoxarifados',
    GESTIO_BANCO = '[Gestio] Bancos',
    GESTIO_BASE_FORNECEDORES = '[Gestio] Base de fornecedores',
    GESTIO_CAIXA_BANCOS = '[Gestio] Caixa/Bancos',
    GESTIO_CARTEIRA = '[Gestio] Carteiras',
    GESTIO_CATEGORIA_CLIENTE = '[Gestio] Categorias de cliente',
    GESTIO_CENTRO_DE_CUSTO = '[Gestio] Centros de custo',
    GESTIO_CENTRO_DE_CUSTO_GRUPO = '[Gestio] Grupos de centro de custo',
    GESTIO_CFOP = '[Gestio] CFOP',
    GESTIO_CLASSIFICACAO_AUXILIAR = '[Gestio] Classificação Auxiliar',
    GESTIO_CLASSIFICACAO_AUXILIAR_ESTOQUE = '[Gestio] Classificação Auxiliar Estoque',
    GESTIO_CLIENTE_ARQUIVOS_ANEXADOS = '[Gestio] Clientes arquivos anexados',
    GESTIO_CODIGO_DE_SERVICO = '[Gestio] Códigos de serviços',
    GESTIO_COLABORADOR_USUARIO = '[Gestio] Usuários colaboradores',
    GESTIO_COLABORADOR_REQUISITANTE = '[Gestio] Requisitantes colaboradores',
    GESTIO_COLABORADOR_GRUPO = '[Gestio] Grupos de colaboradores',
    GESTIO_CONDICAO_DE_PAGAMENTO_PARCELAS = '[Gestio] Parcelas de condição de pagamento',
    GESTIO_CONTA_CORRENTE = '[Gestio] Contas correntes',
    GESTIO_CONTAS_PAGAR = '[Gestio] Contas a pagar',
    GESTIO_CONTAS_PAGAR_BX = '[Gestio] Baixa de contas a pagar',
    GESTIO_CONTAS_PAGAR_RATEIOS = '[Gestio] Rateios de contas a pagar',
    GESTIO_CONTAS_PAGAR_PESQUISAR = '[Gestio] Pesquisa de contas a pagar',
    GESTIO_CONTAS_RECEBER = '[Gestio] Contas a receber',
    GESTIO_CONTAS_RECEBER_RATEIOS = '[Gestio] Rateios de contas a receber',
    GESTIO_CONTAS_RECEBER_BX = '[Gestio] Baixa de contas a receber',
    GESTIO_CONTAS_RECEBER_PREJUIZOS = '[Gestio] Prejuízos em contas a receber',
    GESTIO_CONTAS_RECEBER_PESQUISAR = '[Gestio] Pesquisa de contas a receber',
    GESTIO_CONTRATO_DE_FORNECIMENTO = '[Gestio] Contratos de fornecimento',
    GESTIO_CONTRATO_DE_FORNECIMENTO_PRODUTOS = '[Gestio] Produtos de contratos de fornecimento',
    GESTIO_CONTRATO_DE_FORNECIMENTO_TIPO = '[Gestio] Tipos de contratos de fornecimento',
    GESTIO_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados a contratos',
    GESTIO_CONTRATO_DE_FORNECIMENTO_ATIVOS = '[Gestio] Contratos de fornecimento ativos',
    GESTIO_CONTRATO_DE_FORNECIMENTO_INATIVOS = '[Gestio] Contratos de fornecimento inativos',
    GESTIO_COTACAO_SELECAO_FORNECEDORES = '[Gestio] Seleção de fornecedores para cotação',
    GESTIO_COTACOES_FORNECEDOR = '[Gestio] Cotações por fornecedor',
    GESTIO_COTACOES_ITENS_GRID = '[Gestio] Itens da cotação',
    GESTIO_CR_CP_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados CR/CP',
    GESTIO_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados previsão CR/CP',
    GESTIO_CRM_CLIENTES = '[Gestio] Clientes CRM',
    GESTIO_CRM_CLIENTES_CONTAS_RECEBER = '[Gestio] Contas a receber dos clientes',
    GESTIO_CRM_CLIENTES_CONTATO = '[Gestio] Contatos dos clientes',
    GESTIO_CRM_CLIENTES_PESQUISAR = '[Gestio] Pesquisa de clientes CRM',
    GESTIO_CRM_TIPO_CLIENTES = '[Gestio] Tipos de cliente CRM',
    GESTIO_CRM_SITUACAO_CLIENTES = '[Gestio] Situações dos clientes CRM',
    GESTIO_CUSTO_ABC = '[Gestio] Custo ABC',
    GESTIO_DUPLICATA_COMPRAS = '[Gestio] Duplicatas de compras',
    GESTIO_DUPLICATA_VENDAS = '[Gestio] Duplicatas de vendas',
    GESTIO_DUPLICATA_HISTORICO_CP = '[Gestio] Histórico de duplicatas CP',
    GESTIO_DUPLICATA_HISTORICO_CR = '[Gestio] Histórico de duplicatas CR',
    GESTIO_ENDERECO_DE_ENTREGA = '[Gestio] Endereços de entrega',
    GESTIO_ENTRADA_DE_ESTOQUE = '[Gestio] Entradas de estoque',
    GESTIO_ENTRADA_DE_ESTOQUE_PESQUISAR = '[Gestio] Pesquisa de entradas de estoque',
    GESTIO_ENTRADA_LOTE_ABERTAS = '[Gestio] Lotes de entrada abertos',
    GESTIO_ENTRADA_LOTE_ENCERRADAS = '[Gestio] Lotes de entrada encerrados',
    GESTIO_ENTRADA_LOTE_ITENS = '[Gestio] Itens dos lotes de entrada',
    GESTIO_EXPEDICAO_CANCELADOS = '[Gestio] Expedições canceladas',
    GESTIO_EXPEDICAO_DE_ESTOQUE_PESQUISAR = '[Gestio] Pesquisa de expedições de estoque',
    GESTIO_EXPEDICAO_PENDENTES = '[Gestio] Expedições pendentes',
    GESTIO_EXPEDICAO_EM_PROCESSAMENTO = '[Gestio] Expedições em processamento',
    GESTIO_EXPEDICAO_PROCESSADOS = '[Gestio] Expedições processadas',
    GESTIO_EXPORTACAO_DE_RELATORIO = '[Gestio] Exportação de relatórios',
    GESTIO_EXPEDICAO_POR_REQUISICAO = '[Gestio] Expedições por requisição',
    GESTIO_EXPEDICAO_POR_REQUISICAO_DETALHE = '[Gestio] Detalhes das expedições por requisição',
    GESTIO_FATURA_ABERTAS = '[Gestio] Faturas abertas',
    GESTIO_FATURA_CANCELADAS = '[Gestio] Faturas canceladas',
    GESTIO_FATURA_PROCESSADAS = '[Gestio] Faturas processadas',
    GESTIO_FATURA_SERVICOS = '[Gestio] Faturas de serviços',
    GESTIO_FILIAL = '[Gestio] Filiais',
    GESTIO_FINANCEIRO_CAIXA_BANCOS = '[Gestio] Caixa e bancos',
    GESTIO_FINANCEIRO_CAIXA_CP = '[Gestio] Caixa contas a pagar',
    GESTIO_FINANCEIRO_CAIXA_CR = '[Gestio] Caixa contas a receber',
    GESTIO_FINANCEIRO_CAIXA_DESDOBRAMENTOS = '[Gestio] Caixa desdobramentos',
    GESTIO_CONDICAO_DE_PAGAMENTO = '[Gestio] Condições de pagamento',
    GESTIO_MAPEAMENTO_DE_RATEIO = '[Gestio] Mapeamento de rateios',
    GESTIO_FORNECEDOR = '[Gestio] Fornecedores',
    GESTIO_FORNECEDOR_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados do fornecedor',
    GESTIO_FORNECEDOR_CONTATO = '[Gestio] Contatos do fornecedor',
    GESTIO_FORNECEDOR_COTACOES = '[Gestio] Cotações de fornecedores',
    GESTIO_FORNECEDOR_CP = '[Gestio] Contas a pagar de fornecedores',
    GESTIO_FORNECEDOR_PEDIDO_DE_COMPRA = '[Gestio] Pedidos de compra de fornecedores',
    GESTIO_FORNECEDOR_PESQUISAR = '[Gestio] Pesquisa de fornecedores',
    GESTIO_SEGMENTO = '[Gestio] Segmentos',
    GESTIO_RAMO_DE_ATIVIDADE = '[Gestio] Ramos de atividade',
    GESTIO_FORNECEDOR_TIPO = '[Gestio] Tipos de fornecedores',
    GESTIO_GRUPO_LANCAMENTO = '[Gestio] Grupos de lançamento',
    GESTIO_ITEM_LANCAMENTO = '[Gestio] Itens de lançamento',
    GESTIO_MAPEAMENTO_PRODUTO = '[Gestio] Mapeamento de produtos',
    GESTIO_MOTIVO_DA_COMPRA = '[Gestio] Motivos de compra',
    GESTIO_MOTIVO_REQUISICAO = '[Gestio] Motivos de requisição',
    GESTIO_NCM = '[Gestio] NCM',
    GESTIO_NIVEL_DE_APROVACAO = '[Gestio] Níveis de aprovação',
    GESTIO_NIVEL_DE_APROVACAO_ESTOQUE = '[Gestio] Níveis de aprovação de estoque',
    GESTIO_NOTA_FISCAL_PRODUTO_CANCELADAS = '[Gestio] Notas fiscais de produto canceladas',
    GESTIO_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO = '[Gestio] Cartas de correção de NF de produto',
    GESTIO_NOTA_FISCAL_PRODUTO_EMITIDAS = '[Gestio] Notas fiscais de produto emitidas',
    GESTIO_NOTA_FISCAL_PRODUTO_ITENS = '[Gestio] Itens de notas fiscais de produto',
    GESTIO_NOTA_FISCAL_PRODUTO_SERVICOS = '[Gestio] Serviços em notas fiscais de produto',
    GESTIO_NOTA_FISCAL_PRODUTO_PENDENTES = '[Gestio] Notas fiscais de produto pendentes',
    GESTIO_NOTA_FISCAL_SERVICO_CANCELADAS = '[Gestio] Notas fiscais de serviço canceladas',
    GESTIO_NOTA_FISCAL_SERVICO_EMITIDAS = '[Gestio] Notas fiscais de serviço emitidas',
    GESTIO_NOTA_FISCAL_SERVICO_PENDENTES = '[Gestio] Notas fiscais de serviço pendentes',
    GESTIO_ORDEM_DE_COMPRA_ABERTAS = '[Gestio] Ordens de compra abertas',
    GESTIO_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados às ordens de compra',
    GESTIO_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_POR_PRODUTO = '[Gestio] Arquivos anexados às ordens por produto',
    GESTIO_ORDEM_DE_COMPRA_COTACOES_V2 = '[Gestio] Cotações das ordens de compra',
    GESTIO_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO = '[Gestio] Histórico de cotações por produto',
    GESTIO_ORDEM_DE_COMPRA_ENCERRADAS = '[Gestio] Ordens de compra encerradas',
    GESTIO_ORDEM_DE_COMPRA_LOTE_ABERTAS = '[Gestio] Ordens de compra em lote abertas',
    GESTIO_ORDEM_DE_COMPRA_LOTE_ENCERRADAS = '[Gestio] Ordens de compra em lote encerradas',
    GESTIO_ORDEM_DE_COMPRA_LOTE_ITENS = '[Gestio] Itens das ordens de compra em lote',
    GESTIO_ORDEM_DE_COMPRA_PENDENTE_APROVACAO = '[Gestio] Ordens de compra pendentes de aprovação',
    GESTIO_ORDEM_DE_COMPRA_PESQUISAR = '[Gestio] Pesquisa de ordens de compra',
    GESTIO_ORDEM_DE_COMPRA_PRODUTOS = '[Gestio] Produtos das ordens de compra',
    GESTIO_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA = '[Gestio] Produtos consolidados das ordens de compra',
    GESTIO_ORDEM_DE_COMPRA_PRODUTOS_RATEIO = '[Gestio] Rateios dos produtos das ordens de compra',
    GESTIO_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES = '[Gestio] Seleção de fornecedores das ordens de compra',
    GESTIO_ORDEM_PRODUTO = '[Gestio] Ordem de produto',
    GESTIO_ORDEM_SERVICOS_ABERTAS = '[Gestio] Ordens de serviço abertas',
    GESTIO_ORDEM_SERVICOS_CANCELADAS = '[Gestio] Ordens de serviço canceladas',
    GESTIO_ORDEM_SERVICOS_PROCESSADAS = '[Gestio] Ordens de serviço processadas',
    GESTIO_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL = '[Gestio] Ordens de serviço processadas parcialmente',
    GESTIO_PAIS = '[Gestio] Países',
    GESTIO_PARCELAS_PEDIDO_DE_COMPRA = '[Gestio] Parcelas de pedidos de compra',
    GESTIO_PEDIDO_DE_VENDA_ABERTAS = '[Gestio] Pedidos de venda abertos',
    GESTIO_PEDIDO_DE_VENDA_CANCELADAS = '[Gestio] Pedidos de venda cancelados',
    GESTIO_PEDIDO_DE_VENDA_PROCESSADAS = '[Gestio] Pedidos de venda processados',
    GESTIO_PEDIDO_DE_VENDA_PRODUTO_ITENS = '[Gestio] Itens de produto dos pedidos de venda',
    GESTIO_PEDIDO_DE_COMPRA_ABERTAS = '[Gestio] Pedidos de compra abertos',
    GESTIO_PEDIDO_DE_COMPRA_ENCERRADAS = '[Gestio] Pedidos de compra encerrados',
    GESTIO_PEDIDO_DE_COMPRA_ITENS = '[Gestio] Itens dos pedidos de compra',
    GESTIO_PEDIDO_DE_COMPRA_PESQUISAR = '[Gestio] Pesquisa de pedidos de compra',
    GESTIO_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL = '[Gestio] Processamento parcial dos pedidos de compra',
    GESTIO_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO = '[Gestio] Pedidos de compra pendentes de aprovação',
    GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados aos pedidos',
    GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS_POR_PRODUTO = '[Gestio] Arquivos anexados aos pedidos por produto',
    GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL = '[Gestio] Arquivos anexados às notas fiscais dos pedidos',
    GESTIO_PLANO_DE_CONTAS = '[Gestio] Plano de contas',
    GESTIO_PLANEJAMENTO_ORCAMENTARIO = '[Gestio] Planejamento orçamentário',
    GESTIO_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS = '[Gestio] Planejamento orçamentário de pedidos',
    GESTIO_PREVISAO_CONTAS_PAGAR = '[Gestio] Previsão de contas a pagar',
    GESTIO_PREVISAO_CONTAS_PAGAR_RATEIOS = '[Gestio] Rateios da previsão de contas a pagar',
    GESTIO_PREVISAO_CONTAS_RECEBER = '[Gestio] Previsão de contas a receber',
    GESTIO_PREVISAO_CONTAS_RECEBER_RATEIOS = '[Gestio] Rateios da previsão de contas a receber',
    GESTIO_PREVISAO_FATURAMENTO_SERVICO = '[Gestio] Previsão de faturamento de serviço',
    GESTIO_PREVISAO_FATURAMENTO_SERVICO_ITENS = '[Gestio] Itens da previsão de faturamento de serviço',
    GESTIO_PREVISAO_FATURAMENTO_FATURA = '[Gestio] Previsão de faturamento da fatura',
    GESTIO_PREVISAO_FATURAMENTO_FATURA_SERVICOS = '[Gestio] Serviços da previsão de faturamento da fatura',
    GESTIO_PREVISAO_HISTORICO_CP = '[Gestio] Histórico de previsão CP',
    GESTIO_PREVISAO_HISTORICO_CR = '[Gestio] Histórico de previsão CR',
    GESTIO_PRODUTO = '[Gestio] Produto',
    GESTIO_PRODUTO_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados ao produto',
    GESTIO_PRODUTO_CATEGORIA = '[Gestio] Categoria de produto',
    GESTIO_PRODUTO_ESTOQUE = '[Gestio] Estoque de produto',
    GESTIO_PRODUTO_ESTOQUE_ENDERECAMENTOS = '[Gestio] Endereçamentos do estoque de produto',
    GESTIO_PRODUTO_GRUPO = '[Gestio] Grupo de produto',
    GESTIO_PRODUTO_MOVIMENTACAO = '[Gestio] Movimentação de produto',
    GESTIO_PRODUTO_PEDIDOS_COMPRAS = '[Gestio] Pedidos de compra do produto',
    GESTIO_PRODUTO_PESQUISAR = '[Gestio] Pesquisar produto',
    GESTIO_PRODUTO_SERIES_LOTE = '[Gestio] Séries e lote de produto',
    GESTIO_PRODUTO_SUGESTAO_COMPRAS = '[Gestio] Sugestão de compras do produto',
    GESTIO_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA = '[Gestio] Conversão de unidade de medida do produto',

    GESTIO_PROJETO = '[Gestio] Projeto',
    GESTIO_PROJETO_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados ao projeto',
    GESTIO_PROJETO_MOVIMENTACAO = '[Gestio] Movimentação de projeto',
    GESTIO_PROJETO_PEDIDO_DE_COMPRA = '[Gestio] Pedido de compra do projeto',
    GESTIO_PROJETO_CONTAS_PAGAR = '[Gestio] Contas a pagar do projeto',
    GESTIO_PROJETO_CONTAS_RECEBER = '[Gestio] Contas a receber do projeto',

    GESTIO_RECEBIMENTO_DE_COMPRA_PESQUISAR = '[Gestio] Pesquisar recebimento de compra',
    GESTIO_RECEBIMENTO_DE_COMPRAS_CANCELADOS = '[Gestio] Recebimentos de compras cancelados',
    GESTIO_RECEBIMENTO_DE_COMPRAS_PENDENTES = '[Gestio] Recebimentos de compras pendentes',
    GESTIO_RECEBIMENTO_DE_COMPRAS_PROCESSADOS = '[Gestio] Recebimentos de compras processados',
    GESTIO_RECEBIMENTOS_POR_PEDIDO = '[Gestio] Recebimentos por pedido',
    GESTIO_RECEBIMENTOS_POR_PEDIDO_DETALHE = '[Gestio] Detalhe dos recebimentos por pedido',

    GESTIO_REGRA_DE_APROVACAO = '[Gestio] Regra de aprovação',
    GESTIO_REGRA_DE_APROVACAO_OC = '[Gestio] Regra de aprovação OC',
    GESTIO_REGRA_DE_APROVACAO_OL = '[Gestio] Regra de aprovação OL',
    GESTIO_REGRA_DE_APROVACAO_PC = '[Gestio] Regra de aprovação PC',
    GESTIO_REGRA_DE_APROVACAO_RC = '[Gestio] Regra de aprovação RC',

    GESTIO_REQUISICAO_DE_COMPRAS_ABERTAS = '[Gestio] Requisições de compras abertas',
    GESTIO_REQUISICAO_DE_COMPRAS_ITENS = '[Gestio] Itens das requisições de compras',
    GESTIO_REQUISICAO_DE_COMPRAS_ENCERRADAS = '[Gestio] Requisições de compras encerradas',
    GESTIO_REQUISICAO_DE_COMPRAS_PESQUISAR = '[Gestio] Pesquisar requisição de compras',
    GESTIO_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados da requisição de compras',
    GESTIO_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_POR_PRODUTO = '[Gestio] Arquivos anexados por produto da requisição de compras',
    GESTIO_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO = '[Gestio] Requisição de compras pendente de aprovação',

    GESTIO_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados da requisição de estoque',
    GESTIO_REQUISICAO_DE_ESTOQUE_ABERTAS = '[Gestio] Requisições de estoque abertas',
    GESTIO_REQUISICAO_DE_ESTOQUE_ENCERRADAS = '[Gestio] Requisições de estoque encerradas',
    GESTIO_REQUISICAO_DE_ESTOQUE_ITENS = '[Gestio] Itens das requisições de estoque',
    GESTIO_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS = '[Gestio] Detalhes dos itens da requisição de estoque',
    GESTIO_REQUISICAO_DE_ESTOQUE_PESQUISAR = '[Gestio] Pesquisar requisição de estoque',

    GESTIO_REQUISICAO_PRODUTO = '[Gestio] Requisição de produto',
    GESTIO_SAIDA_DE_ESTOQUE = '[Gestio] Saída de estoque',
    GESTIO_SAIDA_DE_ESTOQUE_PESQUISAR = '[Gestio] Pesquisar saída de estoque',
    GESTIO_SALDOS_EM_ESTOQUE = '[Gestio] Saldos em estoque',
    GESTIO_SECAO = '[Gestio] Seção',

    GESTIO_SUGESTAO_DE_COMPRAS = '[Gestio] Sugestão de compras',
    GESTIO_TIPO_DE_COMPRA = '[Gestio] Tipo de compra',
    GESTIO_TIPO_DE_DOCUMENTO = '[Gestio] Tipo de documento',
    GESTIO_TIPO_DE_PAGAMENTO = '[Gestio] Tipo de pagamento',
    GESTIO_TIPO_PRODUTO = '[Gestio] Tipo de produto',
    GESTIO_TIPO_REQUISICAO = '[Gestio] Tipo de requisição',
    GESTIO_TRANSFERENCIA_EM_LOTE_ESTOQUE = '[Gestio] Transferência em lote de estoque',
    GESTIO_TRANSFERENCIAS = '[Gestio] Transferências',
    GESTIO_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR = '[Gestio] Pesquisa de transferência de estoque',
    GESTIO_UNIDADE_MEDIDA = '[Gestio] Unidade de medida',
    GESTIO_VENDAS_FATURA_PESQUISAR = '[Gestio] Pesquisa de fatura de vendas',
    GESTIO_VENDAS_NFE_PESQUISAR = '[Gestio] Pesquisa de NFe de vendas',
    GESTIO_VENDAS_NFSE_PESQUISAR = '[Gestio] Pesquisa de NFSe de vendas',
    GESTIO_VENDAS_ORDEM_PESQUISAR = '[Gestio] Pesquisa de ordem de vendas',
    GESTIO_VENDAS_PEDIDO_PESQUISAR = '[Gestio] Pesquisa de pedido de vendas',
    GESTIO_VENDAS_ORDEM_SERVICOS = '[Gestio] Ordem de serviços de vendas',
    GESTIO_VENDAS_ORDEM_ARQUIVOS_ANEXADOS = '[Gestio] Arquivos anexados à ordem de vendas',
    GESTIO_VENDAS_PIS = '[Gestio] PIS de vendas',
    GESTIO_VENDAS_VENDEDOR = '[Gestio] Vendedor de vendas',
    GESTIO_COFINS = '[Gestio] COFINS',
    GESTIO_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID = '[Gestio] Itens da requisição de estoque',
    GESTIO_VENDAS_DISCRIMINACAO_SERVICOS = '[Gestio] Discriminação de serviços de vendas',
    GESTIO_VENDAS_NOTA_FISCAL_PRODUTOS = '[Gestio] Nota fiscal de produtos de vendas',
    GESTIO_VENDAS_NOTA_FISCAL_SERVICOS = '[Gestio] Nota fiscal de serviços de vendas',
    GESTIO_ORDEM_COMPOSICAO_FINANCEIRA = '[Gestio] Composição financeira da ordem',
    GESTIO_COTACAO_COMPARACAO = '[Gestio] Comparação de cotação',
    GESTIO_MAPA_DE_COTACOES = '[Gestio] Mapa de cotações',
    GESTIO_PEDIDO_DE_COMPRA_RATEIOS = '[Gestio] Rateios do pedido de compra',
    GESTIO_CRM_CLIENTES_ORDEM_DE_SERVICO = '[Gestio] Ordem de serviço de clientes CRM',
    GESTIO_CRM_CLIENTES_PEDIDO_DE_VENDA = '[Gestio] Pedido de venda de clientes CRM',
    GESTIO_GRUPO_DE_CLIENTE = '[Gestio] Grupo de cliente',
    GESTIO_CRM_CLIENTES_FATURAS = '[Gestio] Faturas de clientes CRM',
    GESTIO_FINANCEIRO_EXTRATO = '[Gestio] Extrato financeiro',
    GESTIO_FINANCEIRO_EXTRATO_LANCAMENTOS = '[Gestio] Lançamentos do extrato financeiro',
    GESTIO_FINANCEIRO_EXTRATO_CRCP = '[Gestio] CRCP do extrato financeiro',
    GESTIO_FINANCEIRO_PAGAMENTOS = '[Gestio] Pagamentos financeiros',
    GESTIO_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA = '[Gestio] Gerar remessa de pagamentos financeiros',
    GESTIO_PRODUTO_PREVISAO_SALDO = '[Gestio] Previsão de saldo de produto',
    GESTIO_PRODUTO_MARGEM_LUCRO = '[Gestio] Margem de lucro do produto',
    GESTIO_CONFIGURACAO_VENDAS = '[Gestio] Configuração de vendas',
    GESTIO_MAPEAMENTO_PADRAO_PRODUTOS = '[Gestio] Mapeamento padrão de produtos',
    GESTIO_BRADESCO_QUESTIONARIO = '[Gestio] Questionário Bradesco',
    GESTIO_TABELA_IBPT = '[Gestio] Tabela IBPT',
    GESTIO_NOTA_FISCAL_PRODUTO_INUTILIZACAO = '[Gestio] Inutilização de nota fiscal de produto',
    GESTIO_ORCAMENTOS_ABERTOS = '[Gestio] Orçamentos abertos',
    GESTIO_ORCAMENTOS_CANCELADOS = '[Gestio] Orçamentos cancelados',
    GESTIO_ORCAMENTOS_PROCESSADOS = '[Gestio] Orçamentos processados',
    GESTIO_ORCAMENTOS_PRODUTOS = '[Gestio] Produtos de orçamentos',
    GESTIO_NOTA_RECEBIDAS = '[Gestio] Notas recebidas',
    GESTIO_NOTA_RECEBIDAS_PRODUTOS = '[Gestio] Produtos de notas recebidas',
    GESTIO_INVENTARIO_CADASTROS = '[Gestio] Cadastros de inventário',
    GESTIO_INVENTARIO_ITENS = '[Gestio] Itens de inventário',
    GESTIO_CNAB_REMESSA_PENDENTE = '[Gestio] Remessa CNAB pendente',
    GESTIO_CNAB_REMESSA_GERADA = '[Gestio] Remessa CNAB gerada',
    GESTIO_CNAB_RETORNO = '[Gestio] Retorno CNAB',
    GESTIO_NOTA_DESTINADAS = '[Gestio] Notas destinadas',
    GESTIO_NOTA_DESTINADAS_PRODUTOS = '[Gestio] Produtos de notas destinadas',
}

export enum GridNameEnum {
    GESTIO_AGENCIA = 'GESTIO_AGENCIA',
    GESTIO_AJUSTE_ESTOQUE = 'GESTIO_AJUSTE_ESTOQUE',
    GESTIO_ALMOXARIFADO = 'GESTIO_ALMOXARIFADO',
    GESTIO_BANCO = 'GESTIO_BANCO',
    GESTIO_BASE_FORNECEDORES = 'GESTIO_BASE_FORNECEDORES',
    GESTIO_CAIXA_BANCOS = 'GESTIO_CAIXA_BANCOS',
    GESTIO_CARTEIRA = 'GESTIO_CARTEIRA',
    GESTIO_CATEGORIA_CLIENTE = 'GESTIO_CATEGORIA_CLIENTE',
    GESTIO_CENTRO_DE_CUSTO = 'GESTIO_CENTRO_DE_CUSTO',
    GESTIO_CENTRO_DE_CUSTO_GRUPO = 'GESTIO_CENTRO_DE_CUSTO_GRUPO',
    GESTIO_CFOP = 'GESTIO_CFOP',
    GESTIO_CLASSIFICACAO_AUXILIAR = 'GESTIO_CLASSIFICACAO_AUXILIAR',
    GESTIO_CLASSIFICACAO_AUXILIAR_ESTOQUE = 'GESTIO_CLASSIFICACAO_AUXILIAR_ESTOQUE',
    GESTIO_CLIENTE_ARQUIVOS_ANEXADOS = 'GESTIO_CLIENTE_ARQUIVOS_ANEXADOS',
    GESTIO_CODIGO_DE_SERVICO = 'GESTIO_CODIGO_DE_SERVICO',
    GESTIO_COLABORADOR_USUARIO = 'GESTIO_COLABORADOR_USUARIO',
    GESTIO_COLABORADOR_REQUISITANTE = 'GESTIO_COLABORADOR_REQUISITANTE',
    GESTIO_COLABORADOR_GRUPO = 'GESTIO_COLABORADOR_GRUPO',
    GESTIO_CONDICAO_DE_PAGAMENTO_PARCELAS = 'GESTIO_CONDICAO_DE_PAGAMENTO_PARCELAS',
    GESTIO_CONTA_CORRENTE = 'GESTIO_CONTA_CORRENTE',
    GESTIO_CONTAS_PAGAR = 'GESTIO_CONTAS_PAGAR',
    GESTIO_CONTAS_PAGAR_BX = 'GESTIO_CONTAS_PAGAR_BX',
    GESTIO_CONTAS_PAGAR_RATEIOS = 'GESTIO_CONTAS_PAGAR_RATEIOS',
    GESTIO_CONTAS_PAGAR_PESQUISAR = 'GESTIO_CONTAS_PAGAR_PESQUISAR',
    GESTIO_CONTAS_RECEBER = 'GESTIO_CONTAS_RECEBER',
    GESTIO_CONTAS_RECEBER_RATEIOS = 'GESTIO_CONTAS_RECEBER_RATEIOS',
    GESTIO_CONTAS_RECEBER_BX = 'GESTIO_CONTAS_RECEBER_BX',
    GESTIO_CONTAS_RECEBER_PREJUIZOS = 'GESTIO_CONTAS_RECEBER_PREJUIZOS',
    GESTIO_CONTAS_RECEBER_PESQUISAR = 'GESTIO_CONTAS_RECEBER_PESQUISAR',
    GESTIO_CONTRATO_DE_FORNECIMENTO = 'GESTIO_CONTRATO_DE_FORNECIMENTO',
    GESTIO_CONTRATO_DE_FORNECIMENTO_PRODUTOS = 'GESTIO_CONTRATO_DE_FORNECIMENTO_PRODUTOS',
    GESTIO_CONTRATO_DE_FORNECIMENTO_TIPO = 'GESTIO_CONTRATO_DE_FORNECIMENTO_TPO',
    GESTIO_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS = 'GESTIO_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS',
    GESTIO_CONTRATO_DE_FORNECIMENTO_ATIVOS = 'GESTIO_CONTRATO_DE_FORNECIMENTO_ATIVOS',
    GESTIO_CONTRATO_DE_FORNECIMENTO_INATIVOS = 'GESTIO_CONTRATO_DE_FORNECIMENTO_INATIVOS',
    GESTIO_COTACAO_SELECAO_FORNECEDORES = 'GESTIO_COTACAO_SELECAO_FORNECEDORES',
    GESTIO_COTACOES_FORNECEDOR = 'GESTIO_COTACOES_FORNECEDOR',
    GESTIO_COTACOES_ITENS_GRID = 'GESTIO_COTACOES_ITENS_GRID',
    GESTIO_CR_CP_ARQUIVOS_ANEXADOS = 'GESTIO_CR_CP_ARQUIVOS_ANEXADOS',
    GESTIO_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS = 'GESTIO_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS',
    GESTIO_CRM_CLIENTES = 'GESTIO_CRM_CLIENTES',
    GESTIO_CRM_CLIENTES_CONTAS_RECEBER = 'GESTIO_CRM_CLIENTES_CONTAS_RECEBER',
    GESTIO_CRM_CLIENTES_CONTATO = 'GESTIO_CRM_CLIENTES_CONTATO',
    GESTIO_CRM_CLIENTES_PESQUISAR = 'GESTIO_CRM_CLIENTES_PESQUISAR',
    GESTIO_CRM_TIPO_CLIENTES = 'GESTIO_CRM_TIPO_CLIENTES',
    GESTIO_CRM_SITUACAO_CLIENTES = 'GESTIO_CRM_SITUACAO_CLIENTES',
    GESTIO_CUSTO_ABC = 'GESTIO_CUSTO_ABC',
    GESTIO_DUPLICATA_COMPRAS = 'GESTIO_DUPLICATA_COMPRAS',
    GESTIO_DUPLICATA_VENDAS = 'GESTIO_DUPLICATA_VENDAS',
    GESTIO_DUPLICATA_HISTORICO_CP = 'GESTIO_DUPLICATA_HISTORICO_CP',
    GESTIO_DUPLICATA_HISTORICO_CR = 'GESTIO_DUPLICATA_HISTORICO_CR',
    GESTIO_ENDERECO_DE_ENTREGA = 'GESTIO_ENDERECO_DE_ENTREGA',
    GESTIO_ENTRADA_DE_ESTOQUE = 'GESTIO_ENTRADA_DE_ESTOQUE',
    GESTIO_ENTRADA_DE_ESTOQUE_PESQUISAR = 'GESTIO_ENTRADA_DE_ESTOQUE_PESQUISAR',
    GESTIO_ENTRADA_LOTE_ABERTAS = 'GESTIO_ENTRADA_LOTE_ABERTAS',
    GESTIO_ENTRADA_LOTE_ENCERRADAS = 'GESTIO_ENTRADA_LOTE_ABERTAS',
    GESTIO_ENTRADA_LOTE_ITENS = 'GESTIO_ENTRADA_LOTE_ITENS',
    GESTIO_EXPEDICAO_CANCELADOS = 'GESTIO_EXPEDICAO_CANCELADOS',
    GESTIO_EXPEDICAO_DE_ESTOQUE_PESQUISAR = 'GESTIO_EXPEDICAO_DE_ESTOQUE_PESQUISAR',
    GESTIO_EXPEDICAO_PENDENTES = 'GESTIO_EXPEDICAO_PENDENTES',
    GESTIO_EXPEDICAO_EM_PROCESSAMENTO = 'GESTIO_EXPEDICAO_EM_PROCESSAMENTO',
    GESTIO_EXPEDICAO_PROCESSADOS = 'GESTIO_EXPEDICAO_PROCESSADOS',
    GESTIO_EXPORTACAO_DE_RELATORIO = 'GESTIO_EXPORTACAO_DE_RELATORIO',

    GESTIO_EXPEDICAO_POR_REQUISICAO = 'GESTIO_EXPEDICAO_POR_REQUISICAO',
    GESTIO_EXPEDICAO_POR_REQUISICAO_DETALHE = 'GESTIO_EXPEDICAO_POR_REQUISICAO_DETALHE',

    GESTIO_FATURA_ABERTAS = 'GESTIO_FATURA_ABERTAS',
    GESTIO_FATURA_CANCELADAS = 'GESTIO_FATURA_CANCELADAS',
    GESTIO_FATURA_PROCESSADAS = 'GESTIO_FATURA_PROCESSADAS',
    GESTIO_FATURA_SERVICOS = 'GESTIO_FATURA_SERVICOS',

    GESTIO_FILIAL = 'GESTIO_FILIAL',
    GESTIO_FINANCEIRO_CAIXA_BANCOS = 'GESTIO_FINANCEIRO_CAIXA_BANCOS',
    GESTIO_FINANCEIRO_CAIXA_CP = 'GESTIO_FINANCEIRO_CAIXA_CP',
    GESTIO_FINANCEIRO_CAIXA_CR = 'GESTIO_FINANCEIRO_CAIXA_CR',
    GESTIO_FINANCEIRO_CAIXA_DESDOBRAMENTOS = 'GESTIO_FINANCEIRO_CAIXA_DESDOBRAMENTOS',
    GESTIO_CONDICAO_DE_PAGAMENTO = 'GESTIO_CONDICAO_DE_PAGAMENTO',
    GESTIO_MAPEAMENTO_DE_RATEIO = 'GESTIO_MAPEAMENTO_DE_RATEIO',
    GESTIO_FORNECEDOR = 'GESTIO_FORNECEDOR',
    GESTIO_FORNECEDOR_ARQUIVOS_ANEXADOS = 'GESTIO_FORNECEDOR_ARQUIVOS_ANEXADOS',
    GESTIO_FORNECEDOR_CONTATO = 'GESTIO_FORNECEDOR_CONTATO',
    GESTIO_FORNECEDOR_COTACOES = 'GESTIO_FORNECEDOR_COTACOES',
    GESTIO_FORNECEDOR_CP = 'GESTIO_FORNECEDOR_CP',
    GESTIO_FORNECEDOR_PEDIDO_DE_COMPRA = 'GESTIO_FORNECEDOR_PEDIDO_DE_COMPRA',
    GESTIO_FORNECEDOR_PESQUISAR = 'GESTIO_FORNECEDOR_PESQUISAR',
    GESTIO_SEGMENTO = 'GESTIO_SEGMENTO',
    GESTIO_RAMO_DE_ATIVIDADE = 'GESTIO_RAMO_DE_ATIVIDADE',
    GESTIO_FORNECEDOR_TIPO = 'GESTIO_FORNECEDOR_TIPO',
    GESTIO_GRUPO_LANCAMENTO = 'GESTIO_GRUPO_LANCAMENTO',
    GESTIO_ITEM_LANCAMENTO = 'GESTIO_ITEM_LANCAMENTO',
    GESTIO_MAPEAMENTO_PRODUTO = 'GESTIO_MAPEAMENTO_PRODUTO',
    GESTIO_MOTIVO_DA_COMPRA = 'GESTIO_MOTIVO_DA_COMPRA',
    GESTIO_MOTIVO_REQUISICAO = 'GESTIO_MOTIVO_REQUISICAO',
    GESTIO_NCM = 'GESTIO_NCM',
    GESTIO_NIVEL_DE_APROVACAO = 'GESTIO_NIVEL_DE_APROVACAO',
    GESTIO_NIVEL_DE_APROVACAO_ESTOQUE = 'GESTIO_NIVEL_DE_APROVACAO_ESTOQUE',
    GESTIO_NOTA_FISCAL_PRODUTO_CANCELADAS = 'GESTIO_NOTA_FISCAL_PRODUTO_CANCELADAS',
    GESTIO_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO = 'GESTIO_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO',
    GESTIO_NOTA_FISCAL_PRODUTO_EMITIDAS = 'GESTIO_NOTA_FISCAL_PRODUTO_EMITIDAS',
    GESTIO_NOTA_FISCAL_PRODUTO_ITENS = 'GESTIO_NOTA_FISCAL_PRODUTO_ITENS',
    GESTIO_NOTA_FISCAL_PRODUTO_SERVICOS = 'GESTIO_NOTA_FISCAL_PRODUTO_SERVICOS',
    GESTIO_NOTA_FISCAL_PRODUTO_PENDENTES = 'GESTIO_NOTA_FISCAL_PRODUTO_PENDENTES',
    GESTIO_NOTA_FISCAL_SERVICO_CANCELADAS = 'GESTIO_NOTA_FISCAL_SERVICO_CANCELADAS',
    GESTIO_NOTA_FISCAL_SERVICO_EMITIDAS = 'GESTIO_NOTA_FISCAL_SERVICO_EMITIDAS',
    GESTIO_NOTA_FISCAL_SERVICO_PENDENTES = 'GESTIO_NOTA_FISCAL_SERVICO_PENDENTES',
    GESTIO_ORDEM_DE_COMPRA_ABERTAS = 'GESTIO_ORDEM_DE_COMPRA_ABERTAS',
    GESTIO_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS = 'GESTIO_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS',
    GESTIO_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_POR_PRODUTO = 'GESTIO_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_POR_PRODUTO',
    GESTIO_ORDEM_DE_COMPRA_COTACOES_V2 = 'GESTIO_ORDEM_DE_COMPRA_COTACOES_V2',
    GESTIO_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO = 'GESTIO_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO',
    GESTIO_ORDEM_DE_COMPRA_ENCERRADAS = 'GESTIO_ORDEM_DE_COMPRA_ENCERRADAS',
    GESTIO_ORDEM_DE_COMPRA_LOTE_ABERTAS = 'GESTIO_ORDEM_DE_COMPRA_LOTE_ABERTAS_  V1',
    GESTIO_ORDEM_DE_COMPRA_LOTE_ENCERRADAS = 'GESTIO_ORDEM_DE_COMPRA_LOTE_ENCERRADAS',
    GESTIO_ORDEM_DE_COMPRA_LOTE_ITENS = 'GESTIO_ORDEM_DE_COMPRA_LOTE_ITENS',
    GESTIO_ORDEM_DE_COMPRA_PENDENTE_APROVACAO = 'GESTIO_ORDEM_DE_COMPRA_PENDENTE_APROVACAO',
    GESTIO_ORDEM_DE_COMPRA_PESQUISAR = 'GESTIO_ORDEM_DE_COMPRA_PESQUISAR',
    GESTIO_ORDEM_DE_COMPRA_PRODUTOS = 'GESTIO_ORDEM_DE_COMPRA_PRODUTOS_V2',
    GESTIO_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA = 'GESTIO_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA',
    GESTIO_ORDEM_DE_COMPRA_PRODUTOS_RATEIO = 'GESTIO_ORDEM_DE_COMPRA_PRODUTOS_RATEIO',
    GESTIO_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES = 'GESTIO_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES',
    GESTIO_ORDEM_PRODUTO = 'GESTIO_ORDEM_PRODUTO',
    GESTIO_ORDEM_SERVICOS_ABERTAS = 'GESTIO_ORDEM_SERVICOS_ABERTAS',
    GESTIO_ORDEM_SERVICOS_CANCELADAS = 'GESTIO_ORDEM_SERVICOS_CANCELADAS',
    GESTIO_ORDEM_SERVICOS_PROCESSADAS = 'GESTIO_ORDEM_SERVICOS_PROCESSADAS',
    GESTIO_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL = 'GESTIO_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL',
    GESTIO_PAIS = 'GESTIO_PAIS',
    GESTIO_PARCELAS_PEDIDO_DE_COMPRA = 'GESTIO_PARCELAS_PEDIDO_DE_COMPRA',
    GESTIO_PEDIDO_DE_VENDA_ABERTAS = 'GESTIO_PEDIDO_DE_VENDA_ABERTAS',
    GESTIO_PEDIDO_DE_VENDA_CANCELADAS = 'GESTIO_PEDIDO_DE_VENDA_CANCELADAS',
    GESTIO_PEDIDO_DE_VENDA_PROCESSADAS = 'GESTIO_PEDIDO_DE_VENDA_PROCESSADAS',
    GESTIO_PEDIDO_DE_VENDA_PRODUTO_ITENS = 'GESTIO_PEDIDO_DE_VENDA_PRODUTO_ITENS',
    GESTIO_PEDIDO_DE_COMPRA_ABERTAS = 'GESTIO_PEDIDO_DE_COMPRA_ABERTAS',
    GESTIO_PEDIDO_DE_COMPRA_ENCERRADAS = 'GESTIO_PEDIDO_DE_COMPRA_ENCERRADAS',
    GESTIO_PEDIDO_DE_COMPRA_ITENS = 'GESTIO_PEDIDO_DE_COMPRA_ITENS',
    GESTIO_PEDIDO_DE_COMPRA_PESQUISAR = 'GESTIO_PEDIDO_DE_COMPRA_PESQUISAR',
    GESTIO_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL = 'GESTIO_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL',
    GESTIO_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO = 'GESTIO_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO',
    GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS = 'GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS',
    GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS_POR_PRODUTO = 'GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS_POR_PRODUTO',
    GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL = 'GESTIO_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL',
    GESTIO_PLANO_DE_CONTAS = 'GESTIO_PLANO_DE_CONTAS',
    GESTIO_PLANEJAMENTO_ORCAMENTARIO = 'GESTIO_PLANEJAMENTO_ORCAMENTARIO',
    GESTIO_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS = 'GESTIO_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS',
    GESTIO_PREVISAO_CONTAS_PAGAR = 'GESTIO_PREVISAO_CONTAS_PAGAR',
    GESTIO_PREVISAO_CONTAS_PAGAR_RATEIOS = 'GESTIO_PREVISAO_CONTAS_PAGAR_RATEIOS',
    GESTIO_PREVISAO_CONTAS_RECEBER = 'GESTIO_PREVISAO_CONTAS_RECEBER',
    GESTIO_PREVISAO_CONTAS_RECEBER_RATEIOS = 'GESTIO_PREVISAO_CONTAS_RECEBER_RATEIOS',
    GESTIO_PREVISAO_FATURAMENTO_SERVICO = 'GESTIO_PREVISAO_FATURAMENTO_SERVICO',
    GESTIO_PREVISAO_FATURAMENTO_SERVICO_ITENS = 'GESTIO_PREVISAO_FATURAMENTO_SERVICO_ITENS',
    GESTIO_PREVISAO_FATURAMENTO_FATURA = 'GESTIO_PREVISAO_FATURAMENTO_FATURA',
    GESTIO_PREVISAO_FATURAMENTO_FATURA_SERVICOS = 'GESTIO_PREVISAO_FATURAMENTO_FATURA_SERVICOS',
    GESTIO_PREVISAO_HISTORICO_CP = 'GESTIO_PREVISAO_HISTORICO_CP',
    GESTIO_PREVISAO_HISTORICO_CR = 'GESTIO_PREVISAO_HISTORICO_CR',
    GESTIO_PRODUTO = 'GESTIO_PRODUTO',
    GESTIO_PRODUTO_ARQUIVOS_ANEXADOS = 'GESTIO_PRODUTO_ARQUIVOS_ANEXADOS',
    GESTIO_PRODUTO_CATEGORIA = 'GESTIO_PRODUTO_CATEGORIA',
    GESTIO_PRODUTO_ESTOQUE = 'GESTIO_PRODUTO_ESTOQUE',
    GESTIO_PRODUTO_ESTOQUE_ENDERECAMENTOS = 'GESTIO_PRODUTO_ESTOQUE_ENDERECAMENTOS',
    GESTIO_PRODUTO_GRUPO = 'GESTIO_PRODUTO_GRUPO',
    GESTIO_PRODUTO_MOVIMENTACAO = 'GESTIO_PRODUTO_MOVIMENTACAO',
    GESTIO_PRODUTO_PEDIDOS_COMPRAS = 'GESTIO_PRODUTO_PEDIDOS_COMPRAS',
    GESTIO_PRODUTO_PESQUISAR = 'GESTIO_PRODUTO_PESQUISAR',
    GESTIO_PRODUTO_SERIES_LOTE = 'GESTIO_PRODUTO_SERIES_LOTE',
    GESTIO_PRODUTO_SUGESTAO_COMPRAS = 'GESTIO_PRODUTO_SUGESTAO_COMPRAS',

    GESTIO_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA = 'GESTIO_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA',

    GESTIO_PROJETO = 'GESTIO_PROJETO',
    GESTIO_PROJETO_ARQUIVOS_ANEXADOS = 'GESTIO_PROJETO_ARQUIVOS_ANEXADOS',
    GESTIO_PROJETO_MOVIMENTACAO = 'GESTIO_PROJETO_MOVIMENTACAO',
    GESTIO_PROJETO_PEDIDO_DE_COMPRA = 'GESTIO_PROJETO_PEDIDO_DE_COMPRA',
    GESTIO_PROJETO_CONTAS_PAGAR = 'GESTIO_PROJETO_CONTAS_PAGAR',
    GESTIO_PROJETO_CONTAS_RECEBER = 'GESTIO_PROJETO_CONTAS_RECEBER',
    GESTIO_RECEBIMENTO_DE_COMPRA_PESQUISAR = 'GESTIO_RECEBIMENTO_DE_COMPRA_PESQUISAR',
    GESTIO_RECEBIMENTO_DE_COMPRAS_CANCELADOS = 'GESTIO_RECEBIMENTO_DE_COMPRAS_CANCELADOS',
    GESTIO_RECEBIMENTO_DE_COMPRAS_PENDENTES = 'GESTIO_RECEBIMENTO_DE_COMPRAS_PENDENTES',
    GESTIO_RECEBIMENTO_DE_COMPRAS_PROCESSADOS = 'GESTIO_RECEBIMENTO_DE_COMPRAS_PROCESSADOS',
    GESTIO_RECEBIMENTOS_POR_PEDIDO = 'GESTIO_RECEBIMENTOS_POR_PEDIDO',
    GESTIO_RECEBIMENTOS_POR_PEDIDO_DETALHE = 'GESTIO_RECEBIMENTOS_POR_PEDIDO_DETALHE',
    GESTIO_REGRA_DE_APROVACAO = 'GESTIO_REGRA_DE_APROVACAO',
    GESTIO_REGRA_DE_APROVACAO_OC = 'GESTIO_REGRA_DE_APROVACAO_OC',
    GESTIO_REGRA_DE_APROVACAO_OL = 'GESTIO_REGRA_DE_APROVACAO_OL',
    GESTIO_REGRA_DE_APROVACAO_PC = 'GESTIO_REGRA_DE_APROVACAO_PC',
    GESTIO_REGRA_DE_APROVACAO_RC = 'GESTIO_REGRA_DE_APROVACAO_RC',
    GESTIO_REQUISICAO_DE_COMPRAS_ABERTAS = 'GESTIO_REQUISICAO_ABERTAS_DE_COMPRAS',
    GESTIO_REQUISICAO_DE_COMPRAS_ITENS = 'GESTIO_REQUISICAO_DE_COMPRAS_ITENS',
    GESTIO_REQUISICAO_DE_COMPRAS_ENCERRADAS = 'GESTIO_REQUISICAO_ENCERRADAS_DE_COMPRAS',
    GESTIO_REQUISICAO_DE_COMPRAS_PESQUISAR = 'GESTIO_REQUISICAO_DE_COMPRAS_PESQUISAR',
    GESTIO_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS = 'GESTIO_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS',
    GESTIO_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_POR_PRODUTO = 'GESTIO_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_POR_PRODUTO',
    GESTIO_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO = 'GESTIO_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO',
    GESTIO_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS = 'GESTIO_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS',
    GESTIO_REQUISICAO_DE_ESTOQUE_ABERTAS = 'GESTIO_REQUISICAO_DE_ESTOQUE_ABERTAS',
    GESTIO_REQUISICAO_DE_ESTOQUE_ENCERRADAS = 'GESTIO_REQUISICAO_DE_ESTOQUE_ENCERRADAS',
    GESTIO_REQUISICAO_DE_ESTOQUE_ITENS = 'GESTIO_REQUISICAO_DE_ESTOQUE_ITENS',
    GESTIO_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS = 'GESTIO_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS',
    GESTIO_REQUISICAO_DE_ESTOQUE_PESQUISAR = 'GESTIO_REQUISICAO_DE_ESTOQUE_PESQUISAR',
    GESTIO_REQUISICAO_PRODUTO = 'GESTIO_REQUISICAO_PRODUTO',
    GESTIO_SAIDA_DE_ESTOQUE = 'GESTIO_SAIDA_DE_ESTOQUE',
    GESTIO_SAIDA_DE_ESTOQUE_PESQUISAR = 'GESTIO_SAIDA_DE_ESTOQUE_PESQUISAR',
    GESTIO_SALDOS_EM_ESTOQUE = 'GESTIO_SALDOS_EM_ESTOQUE',
    GESTIO_SECAO = 'GESTIO_SECAO',
    GESTIO_SUGESTAO_DE_COMPRAS = 'GESTIO_SUGESTAO_DE_COMPRAS',
    GESTIO_TIPO_DE_COMPRA = 'GESTIO_TIPO_DE_COMPRA',
    GESTIO_TIPO_DE_DOCUMENTO = 'GESTIO_TIPO_DE_DOCUMENTO',
    GESTIO_TIPO_DE_PAGAMENTO = 'GESTIO_TIPO_DE_PAGAMENTO',
    GESTIO_TIPO_PRODUTO = 'GESTIO_TIPO_PRODUTO',
    GESTIO_TIPO_REQUISICAO = 'GESTIO_TIPO_REQUISICAO',
    GESTIO_TRANSFERENCIA_EM_LOTE_ESTOQUE = 'GESTIO_TRANSFERENCIA_EM_LOTE',
    GESTIO_TRANSFERENCIAS = 'GESTIO_TRANSFERENCIAS',
    GESTIO_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR = 'GESTIO_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR',
    GESTIO_UNIDADE_MEDIDA = 'GESTIO_UNIDADE_MEDIDA',
    GESTIO_VENDAS_FATURA_PESQUISAR = 'GESTIO_VENDAS_FATURA_PESQUISAR',
    GESTIO_VENDAS_NFE_PESQUISAR = 'GESTIO_VENDAS_NFE_PESQUISAR',
    GESTIO_VENDAS_NFSE_PESQUISAR = 'GESTIO_VENDAS_NFSE_PESQUISAR',
    GESTIO_VENDAS_ORDEM_PESQUISAR = 'GESTIO_VENDAS_ORDEM_PESQUISAR',
    GESTIO_VENDAS_PEDIDO_PESQUISAR = 'GESTIO_VENDAS_PEDIDO_PESQUISAR',
    GESTIO_VENDAS_ORDEM_SERVICOS = 'GESTIO_VENDAS_ORDEM_SERVICOS',
    GESTIO_VENDAS_ORDEM_ARQUIVOS_ANEXADOS = 'GESTIO_VENDAS_ORDEM_ARQUIVOS_ANEXADOS',
    GESTIO_VENDAS_PIS = 'GESTIO_VENDAS_PIS',
    GESTIO_VENDAS_VENDEDOR = 'GESTIO_VENDAS_VENDEDOR',
    GESTIO_COFINS = 'GESTIO_COFINS',
    GESTIO_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID = 'GESTIO_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID',
    GESTIO_VENDAS_DISCRIMINACAO_SERVICOS = 'GESTIO_VENDAS_DISCRIMINACAO_SERVICOS',
    GESTIO_VENDAS_NOTA_FISCAL_PRODUTOS = 'GESTIO_VENDAS_NOTA_FISCAL_PRODUTOS',
    GESTIO_VENDAS_NOTA_FISCAL_SERVICOS = 'GESTIO_VENDAS_NOTA_FISCAL_SERVICO',
    GESTIO_ORDEM_COMPOSICAO_FINANCEIRA = 'GESTIO_ORDEM_COMPOSICAO_FINANCEIRA',
    GESTIO_COTACAO_COMPARACAO = 'GESTIO_COTACAO_COMPARACAO',
    GESTIO_MAPA_DE_COTACOES = 'GESTIO_MAPA_DE_COTACOES',
    GESTIO_PEDIDO_DE_COMPRA_RATEIOS = 'GESTIO_PEDIDO_DE_COMPRA_RATEIOS',
    GESTIO_CRM_CLIENTES_ORDEM_DE_SERVICO = 'GESTIO_CRM_CLIENTES_ORDEM_DE_SERVICO',
    GESTIO_CRM_CLIENTES_PEDIDO_DE_VENDA = 'GESTIO_CRM_CLIENTES_PEDIDO_DE_VENDA',
    GESTIO_GRUPO_DE_CLIENTE = 'GESTIO_GRUPO_DE_CLIENTE',
    GESTIO_CRM_CLIENTES_FATURAS = 'GESTIO_CRM_CLIENTES_FATURAS',
    GESTIO_FINANCEIRO_EXTRATO = 'GESTIO_FINANCEIRO_EXTRATO',
    GESTIO_FINANCEIRO_EXTRATO_LANCAMENTOS = 'GESTIO_FINANCEIRO_EXTRATO_LANCAMENTOS',
    GESTIO_FINANCEIRO_EXTRATO_CRCP = 'GESTIO_FINANCEIRO_EXTRATO_CRCP',
    GESTIO_FINANCEIRO_PAGAMENTOS = 'GESTIO_FINANCEIRO_PAGAMENTOS',
    GESTIO_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA = 'GESTIO_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA',
    GESTIO_PRODUTO_PREVISAO_SALDO = 'GESTIO_PRODUTO_PREVISAO_SALDO',
    GESTIO_PRODUTO_MARGEM_LUCRO = 'GESTIO_PRODUTO_MARGEM_LUCRO',
    GESTIO_CONFIGURACAO_VENDAS = 'GESTIO_CONFIGURACAO_VENDAS',
    GESTIO_MAPEAMENTO_PADRAO_PRODUTOS = 'GESTIO_MAPEAMENTO_PADRAO_PRODUTOS',
    GESTIO_BRADESCO_QUESTIONARIO = 'GESTIO_BRADESCO_QUESTIONARIO',
    GESTIO_TABELA_IBPT = 'GESTIO_TABELA_IBPT',
    GESTIO_NOTA_FISCAL_PRODUTO_INUTILIZACAO = 'GESTIO_NOTA_FISCAL_PRODUTO_INUTILIZACAO',
    GESTIO_ORCAMENTOS_ABERTOS = 'GESTIO_ORCAMENTOS_ABERTOS',
    GESTIO_ORCAMENTOS_CANCELADOS = 'GESTIO_ORCAMENTOS_CANCELADOS',
    GESTIO_ORCAMENTOS_PROCESSADOS = 'GESTIO_ORCAMENTOS_PROCESSADOS',
    GESTIO_ORCAMENTOS_PRODUTOS = 'GESTIO_ORCAMENTOS_PRODUTOS',
    GESTIO_NOTA_RECEBIDAS = 'GESTIO_NOTA_RECEBIDAS',
    GESTIO_NOTA_RECEBIDAS_PRODUTOS = 'GESTIO_NOTA_RECEBIDAS_PRODUTOS',

    GESTIO_INVENTARIO_CADASTROS = 'GESTIO_INVENTARIO_CADASTROS',
    GESTIO_INVENTARIO_ITENS = 'GESTIO_INVENTARIO_ITENS',

    GESTIO_CNAB_REMESSA_PENDENTE = 'GESTIO_CNAB_REMESSA_PENDENTE',
    GESTIO_CNAB_REMESSA_GERADA = 'GESTIO_CNAB_REMESSA_GERADA',
    GESTIO_CNAB_RETORNO = 'GESTIO_CNAB_RETORNO',

    GESTIO_NOTA_DESTINADAS = 'GESTIO_NOTA_DESTINADAS',
    GESTIO_NOTA_DESTINADAS_PRODUTOS = 'GESTIO_NOTA_DESTINADAS_PRODUTOS',
}

export enum TutorialEnum {
    //EMPRESA

    CLIENTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/491448/clientes-como-cadastrar',
    CLIENTE_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/491282/clientes',
    CLIENTE_CATEGORIA = 'https://ajuda.gestio.com.br/kb/pt-br/article/491360/clientes',
    CLIENTE_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/491393/clientes-tipos-de-clientes',
    CLIENTE_SITUACAO = 'https://ajuda.gestio.com.br/kb/pt-br/article/491398/clientes-situacao-de-clientes',
    CONDICAO_DE_PAGAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516048/condicoes-de-pagamento-como-cadastrar',
    CENTRO_DE_CUSTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183765/centros-de-custo-como-cadastrar',
    CENTRO_DE_CUSTO_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516019/grupos-centros-de-custo-como-cadastrar',
    CLASSIFICACAO_AUXILIAR = 'https://ajuda.gestio.com.br/kb/pt-br/article/516049/classificacoes-auxiliares-como-cadastrar',
    COLABORADOR = 'https://ajuda.gestio.com.br/kb/pt-br/article/183772/colaboradores-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20322-0-183772',
    COLABORADOR_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183772/colaboradores-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20322-0-183772#h_29480f3b-8d6a-41a3-b627-d1e40fcf6f30',
    ENDERECOS_ADICIONAIS = 'https://ajuda.gestio.com.br/kb/pt-br/article/485895/enderecos-adicionais',
    FILIAL = 'https://ajuda.gestio.com.br/kb/pt-br/article/491335/passo-a-passo-de-como-cadastrar-filial',
    FORNECEDOR = 'https://ajuda.gestio.com.br/kb/pt-br/article/489623/fornecedores-como-cadastrar',
    FORNECEDOR_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/489734/fornecedores',
    FORNECEDOR_CONTRATO = 'https://ajuda.gestio.com.br/kb/pt-br/article/489772/fornecedores-como-cadastrar-contratos-de-fornecimento',
    FORNECEDOR_CONTRATO_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/489775/fornecedores-como-cadastrar-tipos-de-contrato-de-fornecimento',
    FORMA_DE_PAGAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516053/formas-de-pagamento-como-cadastrar',
    MAPEAMENTO_DE_RATEIO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516051/mapeamento-de-rateio-como-cadastrar',
    PRODUTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785',
    PRODUTO_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_1e50033b-e24a-4da3-882b-a697a0dce3be',
    PRODUTO_CATEGORIA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_95c15283-dd8a-4af7-abf2-c48f52fba471',
    PRODUTO_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_cf54003b-70e2-4218-b7bd-21d9cf448ebd',
    PROJETO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516012/projetos-como-cadastrar',
    TIPO_DE_DOCUMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_bbf1fc85-a7a9-4b32-9b0a-d668c1d5a028',

    //COMPRAS

    COTACOES = 'https://ajuda.gestio.com.br/kb/pt-br/article/183829/cotacao-passo-a-passo-como-comparar?ticketId=&ticketId=&q=&q=&menuId=20335-0-183829',
    ORDEM_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183819/ordens-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20333-0-183819',
    ORDEM_DE_COMPRA_POR_LOTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183824/ordens-em-lote-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20334-0-183824',
    ORDEM_DE_COMPRA_POR_PRODUTO = 'https://ajuda.gestio.com.br/kb/',
    ORDEM_DE_COMPRA_MINHAS_APROVACOES = 'https://ajuda.gestio.com.br/kb/',
    PEDIDO_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183840/pedido-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20337-0-183840',
    PEDIDO_DE_COMPRA_MINHAS_APROVACOES = 'https://ajuda.gestio.com.br/kb/',
    PLANEJAMENTO_ORCAMENTARIO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183857/planejamento-orcamentario-passo-a-passo-como-configurar?ticketId=&q=',
    REQUISICAO_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183813/requisicoes-de-compra-passo-a-passo-como-fazer?ticketId=&q=',
    REQUISICAO_DE_COMPRA_POR_PRODUTO = 'https://ajuda.gestio.com.br/kb/article/183813/requisicoes-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20332-0-183813#h_0071eafd-2bf8-4122-b822-118bfe16126f',
    REQUISICAO_DE_COMPRA_MINHAS_APROVACOES = 'https://ajuda.gestio.com.br/kb/',
    RECEBIMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183853/recebimento-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20338-0-183853',
    RECEBIMENTO_POR_PEDIDO = 'https://ajuda.gestio.com.br/kb',
    NOTAS_RECEBIDAS = 'https://ajuda.gestio.com.br/kb/',
    NOTAS_RECEBIDAS_SINCRONIZADAS = 'https://ajuda.gestio.com.br/kb/',
    MOTIVOS_DA_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_96ec6e27-cf73-48d6-9784-8e9b039eac53',
    NIVEIS_DE_APROVACAO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_6731ef77-4a90-4bed-be5d-c6b3a7997ea4',
    REGRAS_DE_APROVACAO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_aeeb3131-6dad-4ea4-89ee-e52c14f2077f',
    TIPO_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_8b7905dc-189d-4b2b-a480-250a2db8b48e',
    SUGESTOES_DE_COMPRAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183858/sugestoes-de-compras?ticketId=&ticketId=&q=&q=&menuId=20340-0-183858',
    CONFIGURAÇÕES = 'https://ajuda.gestio.com.br/kb',

    //ESTOQUE

    REQUISICAO_DE_ESTOQUE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183863/requisicao-de-estoque-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20342-0-183863',
    EXPEDICAO_POR_PRODUTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183867/expedicao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20343-0-183867',
    EXPEDICAO_POR_REQUISICAO = 'https://ajuda.gestio.com.br/kb',
    ENTRADAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183872/entrada-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20345-0-183872',
    ENTRADAS_EM_LOTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183881/entrada-em-lote-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20349-0-183881',
    SAIDAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183873/no-otk-web-voce-consegue-dar-baixa-na-quantidade-de-um-item-de-f?ticketId=&ticketId=&q=&q=&menuId=20346-0-183873',
    TRANSFERENCIAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183876/transferencia-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20347-0-183876',
    TRANSFERENCIAS_EM_LOTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183884/transferencia-em-lote-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20350-0-183884',
    AJUSTES = 'https://ajuda.gestio.com.br/kb/pt-br/article/183879/ajuste-de-estoque-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20348-0-183879',
    SALDOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183871/saldo-em-estoque-passo-a-passo-como-consultar?ticketId=&ticketId=&q=&q=&menuId=20344-0-183871',
    INVENTARIOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/450004/inventarios-passo-a-passo-como-cria-los?ticketId=&ticketId=&q=&q=&menuId=48751-0-450004',
    ALMOXARIFADOS = 'https://ajuda.gestio.com.br/kb',
    SECOES = 'https://ajuda.gestio.com.br/kb',
    INFORMACOES_COMPLEMENTARES = 'https://ajuda.gestio.com.br/kb',
    ENDERECAMENTOS_DE_PRODUTOS = 'https://ajuda.gestio.com.br/kb',
    MOTIVOS_DE_REQUISICAO_ESTOQUE = 'https://ajuda.gestio.com.br/kb',
    NIVEIS_DE_APROVACAO_ESTOQUE = 'https://ajuda.gestio.com.br/kb',
    REGRAS_DE_APROVACAO_ESTOQUE = 'https://ajuda.gestio.com.br/kb',
    TIPO_DE_REQUISICAO = 'https://ajuda.gestio.com.br/kb',

    //VENDAS

    FATURA = 'https://ajuda.gestio.com.br/kb/article/183923/faturas-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&menuId=20357-0-183923',
    NOTA_FISCAL_PRODUTOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183947/nota-fiscal-de-produto-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20361-0-183947',
    NOTA_FISCAL_SERVICOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183945/nota-fiscal-de-servico-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20360-0-183945',
    ORDEM_DE_SERVICO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183928/ordens-de-servico-passo-a-passo-como-fazer?ticketId=&q=',
    PEDIDO_DE_VENDA = 'https://ajuda.gestio.com.br/kb/pt-br/article/195916/vendas-pedido-de-venda-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=21713-0-195916',
    PREVISAO_FATURA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183943/previsao-nfs-e-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20359-0-183943',
    PREVISAO_SERVICOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183943/previsao-nfs-e-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20359-0-183943',

    //FINANCEIRO

    AGENCIA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_43959041-d1c8-4b4c-9b3e-f009da77386d',
    CATEGORIA = 'https://ajuda.gestio.com.br/kb/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar#h_ed891c45-993f-4cea-9a25-9543ee9d4a10',
    CARTEIRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_9ce08242-eeae-46fe-9489-95a1eb3b224f',
    CONTA_CORRENTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_ba7f7cc2-6739-446d-a746-08f6d76e3def',
    CUSTO_ABC = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_3ec63e27-f23d-4dd6-8125-a5d4cfeecaf0',
    FINANCEIRO_CAIXA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183921/caixa-bancos-passo-a-passo-como-conciliar-saldo?ticketId=&ticketId=&q=&menuId=20355-0-183921',
    FINANCEIRO_CONTAS_A_PAGAR = 'https://ajuda.gestio.com.br/kb/pt-br/article/183915/contas-a-pagar-passo-a-passo-como-inserir?ticketId=&ticketId=&q=&q=&menuId=20353-0-183915',
    FINANCEIRO_CONTAS_A_RECEBER = 'https://ajuda.gestio.com.br/kb/pt-br/article/183898/contas-a-receber-passo-a-passo-como-inserir?ticketId=&ticketId=&q=&menuId=20352-0-183898',
    GRUPO_LANCAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_bd6fe4a5-683d-4e75-9d15-8142836838d9',
    ITEM_LANCAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_ed891c45-993f-4cea-9a25-9543ee9d4a10',
    PREVISAO_CONTAS_A_PAGAR = 'https://ajuda.gestio.com.br/kb/pt-br/article/183918/previsao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20354-0-183918',
    PREVISAO_CONTAS_A_RECEBER = 'https://ajuda.gestio.com.br/kb/pt-br/article/183918/previsao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20354-0-183918',
}
