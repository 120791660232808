import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CmpFooterBarGestioComponent } from './footer-bar/cmp-footer-bar.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule],
    exports: [CmpFooterBarGestioComponent],
    declarations: [CmpFooterBarGestioComponent],
})
export class FooterComponentsGestioModule {}
