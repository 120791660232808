import { Location } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StateModeEnum } from 'src/app/app.enums';

@Component({
    selector: 'gestio-cmp-title-area',
    templateUrl: './cmp-title-area.component.html',
    styleUrls: ['./cmp-title-area.component.scss'],
})
export class CmpTitleAreaGestioComponent implements OnInit, OnDestroy {
    @ViewChild('titleContent') titleContent!: ElementRef;

    backButtonVisible: boolean = false;
    refreshButtonVisible: boolean = false;
    popoverVisible: boolean = false;

    public isEditMode: boolean = false;
    public isBrowserMode: boolean = true;
    private _stateSubscription: Subscription;

    @Input() title: string = 'XXXX';
    @Input() lastModified: string;
    @Input() subTitle: string = '';
    @Input() subTitleLastUpdate: boolean = false;
    @Input() titleVisibleMobile: boolean = false;

    @Input() backButton: boolean = false;
    @Input() refreshButton: boolean = false;

    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.browse);
    @Input() browserModeContent!: TemplateRef<any>;
    @Input() editModeContent!: TemplateRef<any>;

    @Input() help: string = null;
    @Input() widthHelp: string = '300px';

    @Output() refreshClick = new EventEmitter<any>();

    constructor(private _location: Location) {}

    ngOnInit() {
        if (this.subTitle && this.subTitleLastUpdate) {
            const hasTextLastUpdated =
                this.subTitle.includes('Última atualização');

            if (!hasTextLastUpdated) {
                this.subTitle = `Última atualização: ${this.subTitle}`;
            }
        }

        if (this.state$) {
            this._stateSubscription = this.state$.subscribe((r) => {
                this.isEditMode =
                    r == StateModeEnum.edit || r == StateModeEnum.insert;
                this.isBrowserMode =
                    r == StateModeEnum.browse ||
                    r == StateModeEnum.selected ||
                    r == StateModeEnum.undefined;

                this._checkButtons();
            });
        }
    }
    ngOnDestroy(): void {
        if (this.state$) {
            this._stateSubscription.unsubscribe();
        }
    }

    ngOnChanges(): void {
        this._checkButtons();
    }

    private _checkButtons(): void {
        this.backButtonVisible = this.backButton == true && this.isBrowserMode;
        this.refreshButtonVisible =
            this.refreshButton == true && this.isBrowserMode;
    }

    getNativeElement(): ElementRef {
        return this.titleContent;
    }

    actionBtnRefresh(): void {
        this.refreshClick.emit(true);
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    actionBack() {
        this._location.back();
    }
}
