import { Directive, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MessageService } from '../../shared-services/message/message.service';

@Directive({
    selector: '[copyToClipboard]',
})
export class CopyToClipboardDirective {
    @Input() copyToClipboard: FormControl;

    constructor(private messageService: MessageService) {}

    @HostListener('click') onClick() {
        const textToCopy = this.copyToClipboard.value;

        if (textToCopy == null || textToCopy === '') {
            this.messageService.warningMessage('Nada para copiar.');
            return;
        }

        this.copy(textToCopy);
    }

    private copy(text: string) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        const successful = document.execCommand('copy');

        document.body.removeChild(textarea);

        if (successful) {
            this.messageService.successMessage(
                'Texto copiado para a área de transferência!'
            );
        } else {
            this.messageService.errorMessage(
                'Erro ao copiar o texto. Tente novamente.'
            );
        }
    }
}
