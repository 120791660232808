import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxSelectBoxModule } from 'devextreme-angular';

import { SelectboxLabelGestioComponent } from './selectbox-label/selectbox-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxSelectBoxModule,
        FlexLayoutModule,
    ],
    declarations: [SelectboxLabelGestioComponent],
    exports: [SelectboxLabelGestioComponent],
})
export class SelectboxsComponentsGestioModule {}
