import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { hasRequiredField } from '../../../../app.functions';
import { SelectboxDatasource } from '../../../../core/models/selectbox-datasource.model';

@Component({
    selector: 'gestio-selectbox-label',
    templateUrl: './selectbox-label.component.html',
    styleUrls: ['./selectbox-label.component.scss'],
})
export class SelectboxLabelGestioComponent implements OnInit {
    @Input() label: string = 'Selecione';
    @Input() dataSource: SelectboxDatasource[] = [];
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() noMarginBottom: boolean = false;

    @Output() onSelectedValue = new EventEmitter<any>();

    showClearButton: boolean = false;
    showDropDownButton: boolean = true;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {
        // console.log("SELECTBOX-LABEL DATASOURCE =>", this.dataSource);
    }

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionSelectionChanged(e: any) {
        // console.log("CMP SELECTBOX CHANGED --> ", e.selectedItem);
        if (!e || !e.selectedItem) {
            this.onSelectedValue.emit(null);
            return;
        }
        this.onSelectedValue.emit(e.selectedItem);
    }
}
