import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxPopoverModule, DxTextBoxModule } from 'devextreme-angular';
import { TelefoneGestioComponent } from './telefone/telefone.component';
import { TextboxLabelGestioComponent } from './textbox-label/textbox-label.component';
import { TextboxDisabledLabelGestioComponent } from './textbox-disabled-label/textbox-disabled-label.component';
import { CopyToClipboardModule } from '../../directives/copy-to-clipboard.module';
import { LabelGestioModule } from '../label/label.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxPopoverModule,
        FlexLayoutModule,
        CopyToClipboardModule,
        LabelGestioModule
    ],
    declarations: [
        TextboxLabelGestioComponent,
        TextboxDisabledLabelGestioComponent,
        TelefoneGestioComponent,
    ],
    exports: [
        TextboxLabelGestioComponent,
        TextboxDisabledLabelGestioComponent,
        TelefoneGestioComponent,
    ],
})
export class TextboxsComponentsGestioModule {}
