import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxDateBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';

import { DateboxLabelGestioComponent } from './datebox-label/datebox-label.component';
import { DateboxDisabledLabelGestioComponent } from './datebox-disabled-label/datebox-disabled-label.component';
import { CopyToClipboardModule } from '../../directives/copy-to-clipboard.module';
import { LabelGestioModule } from '../label/label.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DxPopoverModule,
        DxTemplateModule,
        DxDateBoxModule,
        LabelGestioModule
    ],
    declarations: [
        DateboxLabelGestioComponent,
        DateboxDisabledLabelGestioComponent,
    ],
    exports: [DateboxLabelGestioComponent, DateboxDisabledLabelGestioComponent],
})
export class DateboxsComponentsGestioModule {}
