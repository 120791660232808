import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'gestio-cmp-radio-group-label',
    templateUrl: './cmp-radio-group-label.component.html',
    styleUrls: ['./cmp-radio-group-label.component.scss'],
})
export class CmpRadioGroupLabelGestioComponent implements OnInit {
    @Input() label: string = null;
    @Input() value: any;
    @Input() control: FormControl;
    @Input() layout: string = 'horizontal';
    @Input() dataSource: DataSource;
    @Input() disabled: boolean = false;
    @Output() onValueChanged = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        return this.label;
    }

    actionValueChanged(event) {
        this.onValueChanged.emit(event);
    }
}
